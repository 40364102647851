import React, { useState, useEffect } from 'react';
import styled from "styled-components";
import { Helmet } from "react-helmet";

const FlipperFrenzy = () => {
    const [scrollPos, setScrollPos] = useState(0);

    useEffect(() => { 
        const handleScroll = () => {
            setScrollPos(window.scrollY);
        };
      
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <>
            <Helmet>
                <title>FlipperFrenzy - Seal the Fun!</title>
                <meta name="description" content="Join the whimsical world of FlipperFrenzy, a seal racing game where fun meets the flippers!" />
            </Helmet>

            <Container>
                <Background scrollPos={scrollPos}>
                    <img src="/images/FlipperFrenzySplash.jpg" alt="FlipperFrenzy Splash" />
                </Background>
            </Container>
           
     <BodyContainer>
    
     <Title>Basically a Seal Racing Game</Title>
    <GameDescription>
        
    <PanelSingle>
      
        <AboutGame>
          Welcome to the whimsical, wobbly world of "FlipperFrenzy," currently flapping through early prototype development...
          {/* More intro text */}
        </AboutGame>
      
    </PanelSingle>

    <Panel>
    <TextColumn>
        <AboutGame>
        In the world of "FlipperFrenzy," it's not just about some seals racing; it's about mastering the art of the 'galumph'—a move that's half grace, half belly-flop, and all strategy.
          {/* More intro text */}
        </AboutGame>
      </TextColumn>
      <VideoColumn>
      <iframe src="https://player.vimeo.com/video/908848074?controls=0&autoplay=1&playsinline=1&color&autopause=0&loop=1&muted=1&title=1&portrait=1&byline=1#t=">
    
      </iframe>
      
      </VideoColumn>
     
    </Panel>

    <Panel>
      <VideoColumn>
      <iframe src="https://player.vimeo.com/video/908763872?controls=0&autoplay=1&playsinline=1&color&autopause=0&loop=1&muted=1&title=1&portrait=1&byline=1#t="></iframe>
      </VideoColumn>
      <TextColumn>
        <AboutGame>
        Navigate through icy courses with a hop, a skip, and an occasional swim, showcasing the kind of aquatic agility that would make a dolphin do a double-take. As the sole dev of this chilly race-a-thon, I'm dreaming big—envisioning multiplayer madness where friends can join in the flipper fun. 


          {/* More intro text */}
        </AboutGame>
      </TextColumn>
    </Panel>

    <Panel>  
      <TextColumn>
        <AboutGame>
        Imagine racing through stages inspired by the world's coldest corners, from the iceberg-dotted waters of the Antarctic to the sun-kissed shores of Hawaii. 
          {/* More intro text */}
        </AboutGame>
      </TextColumn>
      <VideoColumn>
      <iframe src="https://player.vimeo.com/video/929743047?controls=0&autoplay=1&playsinline=1&color&autopause=0&loop=1&muted=1&title=1&portrait=1&byline=1#t=">
      </iframe>
      </VideoColumn>
    </Panel>
    <Panel>
      <VideoColumn>
      <iframe src="https://player.vimeo.com/video/929737500?controls=0&autoplay=1&playsinline=1&color&autopause=0&loop=1&muted=1&title=1&portrait=1&byline=1#t="></iframe>
      </VideoColumn>
      <TextColumn>
        <AboutGame>
        And what's a race without a lineup of the sleekest competitors? From the agile Hawaiian monk seal to the robust harbor and harp seals, and even the fierce leopard seal, "FlipperFrenzy" aims to bring a global gathering of these flippered friends to your screen.


          {/* More intro text */}
        </AboutGame>
      </TextColumn>
    </Panel>

    <Panel>  
      <TextColumn>
        <AboutGame>
        Stay tuned as "FlipperFrenzy" continues to evolve, where the only thing more exciting than the race is the splash you'll make when you join in.
          {/* More intro text */}
        </AboutGame>
      </TextColumn>
      <VideoColumn>
      <iframe src="https://player.vimeo.com/video/929739992?controls=0&autoplay=1&playsinline=1&color&autopause=0&loop=1&muted=1&title=1&portrait=1&byline=1#t=">
      </iframe>
      </VideoColumn>
    </Panel>

    
    </GameDescription>
 
 
  </BodyContainer>
        </>
    );
};

const Title=styled.div`
justify-content: left;
text-align: left;
align-items: left;

font-family: 'Caveat Brush', cursive;
  font-size: 6em;
  color: white;
  line-height: 1;
  margin-bottom: 20px;
  margin-left: 100px;
  text-shadow: 2px 2px 4px rgba(124,180,211,1);; /* X-offset, Y-offset, blur radius, color */
`;

const Container = styled.div`

 @media (max-width:800px)
 {
  width:100vw;
  width:400px;
  overflow:hidden;
 }


`;

const Background = styled.div`

    background-repeat: no-repeat;
    background-size: cover;
    position: fixed;
    top: 0;
    width: 100%;
    height: auto;
    min-height: 500px;
    z-index: -1;
    filter: ${({ scrollPos }) => `blur(${Math.min(scrollPos / 10, 10)}px)`};

    img { 
        width: 100%;
        height: auto;
    }

    @media (max-width: 800px) {
        width: 100%;
    }
`;



const BodyContainer = styled.div`
    padding-top: 400px;
    position: relative;
    left: 70px;
    width: 80%;
    max-width: 1200px;
    margin: 0 auto;
    min-height: 4800px;

    @media (max-width: 800px) {
        left: 24px;
        width: 95%;
    }
`;


const GameDescription = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;
`;

const PanelSingle = styled.div`
background-color: rgba(25,25,25,.25);
background-color: rgba(80,113,128,.3);

box-shadow: 2px 2px 3px 3px rgba(0,0,0,0.1);; 
border-radius:12px;
padding:20px;
min-width:1000px;
max-width:1000px;
margin-top:20px;
z-index:2;

width: 1000px;
flex-direction: row ;
align-items:center;
justify-content: center;
text-align: center;

`;


const Panel = styled.div`

position: relative;
display:flex;

background-color: rgba(25,25,25,.25);
background-color: rgba(157,221,249,.3);
background-color: rgba(80,113,128,.3);
box-shadow: 2px 2px 3px 3px rgba(0,0,0,0.1);; 
border-radius:12px;
padding:20px;
min-width:1000px;
max-width:1000px;
margin-top:20px;
z-index:2;

p{
  max-width:600px;
  margin:0 0 0 30px;
}

img{
  min-width:300px;
  height:100%;
  box-shadow: 4px 4px 2px 2px rgba(0,0,0,0.5);; 
  border-radius:12px;
}

@media (max-width:800px)
{
  flex-wrap: wrap;
  padding:16px;
  max-width:330px;
  p{
    max-width:330px;
    margin: 32px 0;
  }
}


`;


const VideoColumn = styled.div`
  flex: 1;
  padding-top:32px;
  padding-bottom:32px;
  padding-left:16px;
  padding-right:16px;
iframe {
  margin:0;
  padding:0;
  width: 500px;
  height: 280px;
  border-radius: 12px; 
  box-shadow: 4px 4px 2px 2px rgba(0,0,0,0.25);;  
  border: solid 2px rgba(255,255,255,0.3);
}
`;

const TextColumn = styled.div`
  flex: 1;
  padding: 20px;
`;

const AboutGame = styled.p`
  font-family: 'Caveat Brush', cursive;
  font-size: 1.5em;
  color: white;
  line-height: 1.4;
  margin-bottom: 20px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25); /* X-offset, Y-offset, blur radius, color */
`;



export default FlipperFrenzy;
