import React, { useRef, useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { CSSTransition } from 'react-transition-group';
import { Helmet } from "react-helmet";

const DemonBlaster = (props) => {
  const [scrollPos, setScrollPos] = useState(0);
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => { 
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScroll = () => {
    setScrollPos(window.scrollY);
  };

    let settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
    };

  return (
    <>
    <Helmet>
      
        <title>DemonBlaster</title>
        <meta name="description" content="Demon Blaster: a VR Multiplayer Online Battle Arena game that can be played with or without VR. Fast-paced, tactical matches. Incorporates VR motion controls for unique interaction with the world." />
        <link rel="canonical" href ="/demonblaster" />
        
    </Helmet>

      <Container>
        <Background scrollPos={scrollPos}>
          <img src="/images/bdbg_edited.png" />
        </Background>

      </Container>
     

      <BodyContainer>
      <DemonLeft><img src="/images/DemonsLeft.png" /></DemonLeft>
        <HeroBlock> 
        <iframe src="https://player.vimeo.com/video/262119319"></iframe>
          <h1>Demon Blaster</h1>
          <p>Demon Blaster is VR Multiplayer Online Battle Arena that can be interchangeably played with and without VR setup. Crossover functionality allows for players with only one headset to battle or co-op right at home.  Two teams fight against each other in fast-paced, tactical matches full of action and mayhem.  Like many MOBAs, maintains key principals such as three-lane routes, base cores, towers and hordes of NPCs. However, it simplifies the model by eliminating the leveling up and creeping aspect; and the obvious of being VR accessible. In fact, the game is built from the ground up with this mind, giving you the freedom to explore the world and interact with your surroundings. <br></br><br></br>
          The VR control setup mimics that of a convention first-person shooter with free Locomotion and also incorporates locked yaw rotation for easy turning.  Players can run, jump and shoot but can also utilize world objects with VR motion controls.<br></br><br></br>
          The game's style is greatly inspired by titles such as Psychonauts, dungeon keeper and Earthworm Jim, and invokes a surrealist humor edge and emphasizes on exaggerated animation principles such as squash and stretch and Overlapping Action. </p>   
        
        <br></br>
          <h2>PROTOTYPE: </h2>
          <DownloadLink>
  <a href="nft.rolandstudios.com/VR_MOBA_TEST_B_Data.zip" alt="DemonBlasterBuild" download>
    Download Early Build (573Mb) 
  </a>
  <p>requires HTC VIVE</p>
</DownloadLink>
         
          <Carousel 
          {...settings} 
          beforeChange={(oldIndex, newIndex) => setCurrentSlide(newIndex)}
          afterChange={(index) => setCurrentSlide(index)}
        >
          <Wrap><SliderContainer index={0} currentSlide={currentSlide} bgImage="/images/demonblaster_still_01.png"></SliderContainer></Wrap>
          <Wrap><SliderContainer index={1} currentSlide={currentSlide} bgImage="/images/demonblaster_still_02.png"></SliderContainer></Wrap>
          <Wrap><SliderContainer index={2} currentSlide={currentSlide} bgImage="/images/demonblaster_still_03.png"></SliderContainer></Wrap>
          <Wrap><SliderContainer index={3} currentSlide={currentSlide} bgImage="/images/demonblaster_still_04.png"></SliderContainer></Wrap>
          <Wrap><SliderContainer index={4} currentSlide={currentSlide} bgImage="/images/demonblaster_still_05.png"></SliderContainer></Wrap>
          <Wrap><SliderContainer index={5} currentSlide={currentSlide} bgImage="/images/demonblaster_still_06.png"></SliderContainer></Wrap>
          <Wrap><SliderContainer index={6} currentSlide={currentSlide} bgImage="/images/demonblaster_still_07.png"></SliderContainer></Wrap>
          <Wrap><SliderContainer index={7} currentSlide={currentSlide} bgImage="/images/demonblaster_still_08.png"></SliderContainer></Wrap>
          <Wrap><SliderContainer index={8} currentSlide={currentSlide} bgImage="/images/demonblaster_still_09.png"></SliderContainer></Wrap>
          <Wrap><SliderContainer index={9} currentSlide={currentSlide} bgImage="/images/demonblaster_still_10.png"></SliderContainer></Wrap>
          </Carousel>

        </HeroBlock>
        <DemonRight>
        <img src="/images/DemonsRight.png" />
          </DemonRight>
      </BodyContainer>
    
     
    </>
  );
}

const DownloadLink =styled.div`
color:yellow;
font-size:14px;
a{
  color:yellow;
}
p{
  
  font-size:10px;
}
`;

const SliderContainer = styled.div`
  background-repeat: no-repeat;
  background-size: cover;
  height: 360px;
  margin: 10px;
  border-radius: 12px;
  opacity: 0.1;
  transform: scale(.80);
  transition: opacity 1s, transform 1s;
  box-shadow: 2px 2px 10px 2px black; 
  ${props => props.index === props.currentSlide && css`
    opacity: ${props.index === props.currentSlide ? 1 : 0.1};
    transform: scale(${props.index === props.currentSlide ? 1 : 0.8});
  `}

  ${props => props.bgImage && css`
    background-image: url(${props.bgImage});
  `}
`;

const Carousel = styled(Slider)`

  margin-top: 20px;

  & > button {
    opacity: 0;
    height: 100%;
    width: 5vw;
   
  
    &:hover {
      opacity: 1;
      transition: opacity 0.2s ease 0s;
    }
  }

  ul li button {
    &:before {
      font-size: 10px;
      color: rgb(150, 158, 171);
    }
  }

  li.slick-active button:before {
    color: white;
  }

  .slick-list {
    overflow: initial;
  }

  .slick-prev {
    left: -75px;
  }

  .slick-next {
    right: -75px;
  }
`;

const Wrap = styled.div`

`;
const DemonLeft =styled.div`
opacity: 0;
margin:0px -10px 400px 0px;
animation: move-fade-left .5s .25s ease-in-out;
animation-fill-mode: forwards;
@keyframes move-fade-left {
  0% {
    opacity: 0;
    transform: scale(70%) translateX(20%);
  }
  100% {
    opacity: 1;
    transform: scale(70%) translateX(0);
  }
}
@media (max-width:800px)
{
  display:none;
}
`;
const DemonRight =styled.div`
opacity: 0;
margin:0px 0px 450px 30px;
transform: scale(110%);
animation: move-fade-right .5s .25s ease-in-out;
animation-fill-mode: forwards;
@keyframes move-fade-right {
  0% {
    opacity: 0;
    transform: scale(110%) translateX(-20%);
  }
  100% {
    opacity: 1;
    transform: scale(110%) translateX(0);
  }
}
@media (max-width:800px)
{
  display:none;
}
`;
const Container = styled.div`
  position: absolute;
  background-color: #1b0401;
  z-index: -2;
  width: 100%;
  height: 100vw;
  @media (max-width:800px){
    //max-width:400px;
    width:100vw;
    height:100vh;
    min-height:1500px;
    background-color: #1b0401;
    opacity:1;
    overflow:hidden;
  }

`;

const Background = styled.div`
  background-repeat: repeat-x;
  position: relative;
  opacity: 1;
  z-index: -1;
  min-height: 500px;
  width: 100%;
  height: 100%;
  background-color: #1b0401;
  filter: ${({ scrollPos }) => `blur(${Math.min(scrollPos / 10, 10)}px) brightness(100%)`};
  img { 
    width: 100%;
  }

  @media (max-width:800px)
  {
    width:800px;
  }
`;
const BodyContainer = styled.div`
  
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  top: 200px;
  bottom: 0;
  left: 0;
  right: 0;
 
  @media (max-width:800px)
  {
    overflow:hidden;
    top: 100px;
    //background-color: #1b0401;
  }
`;
const HeroBlock = styled.div`

width: 640px;
  iframe{
    box-shadow: 2px 2px 10px 2px #333;   
    background-color: black;
    border: 2px solid rgb(255, 255, 255, .25);
    position: center;

    border-radius: 12px;
    width: 640px;
    height: 360px;
    box-shadow: rgb(0 0 0 / 69%) 0px 26px 30px -10px,
    rgb(0 0 0 / 73%) 0px 16px 10px -10px;
  }

  @media (max-width:800px)
  {
    width:330px;
    iframe{
      width: 330px;
      height: 190px;
    }
  }
`;

export default DemonBlaster;