import firebase from "firebase";

const firebaseConfig = {
  apiKey: "AIzaSyBJSNwrWrt1f_jMuGae8Q7huQmv2MaTdis",
  authDomain: "px-mediahub.firebaseapp.com",
  projectId: "px-mediahub",
  storageBucket: "px-mediahub.appspot.com",
  messagingSenderId: "550002654749",
  appId: "1:550002654749:web:8842ecf1098345261390f4",
  measurementId: "G-QBEE996VQL"
};


const firebaseApp = firebase.initializeApp(firebaseConfig);
const db = firebaseApp.firestore();
const auth = firebase.auth();
const provider = new firebase.auth.GoogleAuthProvider();
const storage = firebase.storage();

export { auth, provider, storage };
export default db;
