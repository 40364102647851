import React from 'react';
import styled from "styled-components";

const Footer = (props) => {
return (
<FooterContainer>

<SocialMedia>
<a href="https://www.facebook.com/Rolandstudiosofficial/">
<img src="/images/social/facebook.svg" alt="Facebook"/>
</a>
<a href="https://twitter.com/roland_studios">
<img src="/images/social/twitter.svg" alt="Twitter"/>
</a>
<a href="https://www.instagram.com/rolandstudiosofficial/">
<img src="/images/social/instagram.svg" alt="Instagram"/>
</a>
<a href="https://www.youtube.com/channel/UCN_SWgEDQtgDQE3o0l_CQxQ">
<img src="/images/social/youtube.svg" alt="Youtube"/>
</a>
<a href="https://www.linkedin.com/in/roland-womack-8b9a397/">
<img src="/images/social/linkedin.svg" alt="Linkedin"/>
</a>
<a href="https://discord.gg/G9MVnBpzTg">
<img src="/images/social/discord.svg" alt="Discord"/>
</a>
<a href="https://www.twitch.tv/rolandstudios">
<img src="/images/social/twitch.svg" alt="Twitch"/>
</a>
</SocialMedia>
<Copywrite>Copyright © 2023 RolandStudios</Copywrite>
</FooterContainer>
);
};

const FooterContainer = styled.footer`
position: fixed;
bottom: 0;
width: 100%;
display: flex;
justify-content: center;
align-items: center;
z-index:11;
//background-color: #242424;

background-color: rgba(255,255,255,0.05);  /* translucent white background */
  backdrop-filter: blur(8px); /* blurs background */
  border: 1px solid rgba(255,255,255,0.1);

color: white;
padding: 0px;

.copyright {
margin-right: auto;
}

.social-icons {
display: flex;
margin-left: auto;

a {
color: white;
margin: 0 10px;
font-size: 20px;
transition: all 0.2s ease-in-out;
&:hover {
transform: scale(1.1);
}
}
}
`;
const SocialMedia = styled.div` 
display: flex; 
z-index:12;
justify-content: flex-start;
width: 100%;
padding: 5px 25px;
  a { 
    color: white;
    margin: 0 5px;
    font-size: 8px; 
    transition: all 0.2s 
    ease-in-out; &:hover
    { 
        transform: scale(1.1); 
    } img { 
      width: 24px; 
      height: 24px; 
      margin-right: 10px; 
      } 
  }`;

  const Copywrite = styled.div` 
  z-index:12;
  white-space: nowrap;
  position: absolute;
  margin:10px;
  color: white; 
  justify-content: center;
  font-size: 12px;
  text-align: center;

   margin-top: 12px;
   font-weight:100;

   @media (max-width: 1100px) {
    position: relative;
    font-size: 12px;
    }

    @media (max-width: 600px) {
      position: relative;
      font-size: 0px;
      }
  `;


export default Footer;