import React, { useRef, useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { CSSTransition } from 'react-transition-group';
import { Helmet } from "react-helmet";

const Daydreamer = (props) => {
  const [scrollPos, setScrollPos] = useState(0);
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => { 
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScroll = () => {
    setScrollPos(window.scrollY);
  };

    let settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      arrows:true

    };

  return (
    <>
    <Helmet>
      
        <title>Daydreamer</title>
        <meta name="description" content="Daydreamer: Classic side-scrolling shooter on a planet overrun by evil aliens. Fight mutants & giant bugs. Spirit Animal support & epic boss battles." />
        <link rel="canonical" href ="/daydreamer" />
      
    </Helmet>

      <Container>
        <Background scrollPos={scrollPos}>
        <video src="/videos/Trailer4-HD.mp4" autoPlay loop muted />
        </Background>

    
     

      <BodyContainer>
      <HeroLeft><img src="/images/HeroLeft.png" /></HeroLeft>
        <HeroBlock> 
       
        <img src="/images/DaydreamerMasthead.png"></img>
          <h1>Daydreamer</h1> 
          <p>Daydreamer is the story about a transcendental metaphysical clairvoyant spiritually-evolved crystal child, who awakens from a hyperbaric chamber on her home planet that was taken over by evil aliens thousands of years ago. The world is a dangerous place with radioactive warfare, genetically mutated aliens, and giant bugs.
The game plays like a conventional side-scrolling shooter, greatly inspired by Megaman and older Treasure Games, i.e. Gun-star Heroes/Alien Soldiers, from the 16-bit era. Aside from platforming and shooting, the game also has “Spirit Animals” support character mercenaries that give the player a tactical edge amongst all the chaos. The game is very Boss Focused and plays through like a classic Arcade Shoot em up with off-road checkpoints similar to Metroid.
            <br></br><br></br>
            The look of the game is primarily pre-rendered cgi sprites. Since the dawn of the N64, the law of the land has always been “everything 3d”. Everything had to be “a connected all in one immersive experience,” a common buzzword that has been used to death. I miss games that were just an “awesome experience”. I have always admired the graphical fidelity of titles like Donkey Kong Country and Final Fantasy VII. Admittedly they look dated in hindsight, but the details are what made it work. When thinking about a graphical style for the game, I always wondered what games would have looked like had developers continue this route. Today most platformers are either Puppet Rigged Graphics, Pixel Art Throwbacks, or have real-time DirectX/OpenGL 3d rendering. All of them have their own inherent beauty, but that being said, I believe that we live in a day and age when most computer processing can handle HD sprites, and there are many possibilities that we have yet to explore.
            </p>
        <br></br>
        <h2>Q&A:</h2>
        <iframe width="560" height="315" src="https://www.youtube.com/embed/oLMu-LVOjeY"  frameborder="0"  allowfullscreen></iframe>
        <p>A brief Q&A devlog with Atus during the production the extended Daydreamer"Awakened" edition</p>
        <br></br>
       <h2>TEASER TRAILER:</h2>
        <iframe src="https://player.vimeo.com/video/162305642"></iframe>
        <p>The final teaser trailer for Daydreamer"Awakened" edition</p>
        <br></br>
         <h2>CINEMATIC:</h2>
        <iframe src="https://player.vimeo.com/video/136425355"></iframe>
        <p>The initial proof of concept cinematic refers to a short animation that was created as a demonstration of the potential visual style and storytelling for a project. This animation was self-produced and created using 3D modeling software, 3ds Max, and a rendering engine, Vray.</p>
        <br></br>
 
          <h2>Stills:</h2>
         
          <Carousel 
          {...settings} 
          beforeChange={(oldIndex, newIndex) => setCurrentSlide(newIndex)}
          afterChange={(index) => setCurrentSlide(index)}
        >
       
          <Wrap><SliderContainer index={0} currentSlide={currentSlide} bgImage="/images/daydreamer_still_01.png"></SliderContainer></Wrap>
          <Wrap><SliderContainer index={1} currentSlide={currentSlide} bgImage="/images/daydreamer_still_02.png"></SliderContainer></Wrap>
          <Wrap><SliderContainer index={2} currentSlide={currentSlide} bgImage="/images/daydreamer_still_03.png"></SliderContainer></Wrap>
          <Wrap><SliderContainer index={3} currentSlide={currentSlide} bgImage="/images/daydreamer_still_04.png"></SliderContainer></Wrap>
          <Wrap><SliderContainer index={4} currentSlide={currentSlide} bgImage="/images/daydreamer_still_05.png"></SliderContainer></Wrap>
          <Wrap><SliderContainer index={5} currentSlide={currentSlide} bgImage="/images/daydreamer_still_06.png"></SliderContainer></Wrap>
          <Wrap><SliderContainer index={6} currentSlide={currentSlide} bgImage="/images/daydreamer_still_07.png"></SliderContainer></Wrap>
          <Wrap><SliderContainer index={7} currentSlide={currentSlide} bgImage="/images/daydreamer_still_08.png"></SliderContainer></Wrap>
          <Wrap><SliderContainer index={8} currentSlide={currentSlide} bgImage="/images/daydreamer_still_09.png"></SliderContainer></Wrap>
          <Wrap><SliderContainer index={9} currentSlide={currentSlide} bgImage="/images/daydreamer_still_10.png"></SliderContainer></Wrap>
          <Wrap><SliderContainer index={10} currentSlide={currentSlide} bgImage="/images/daydreamer_still_11.png"></SliderContainer></Wrap>
          <Wrap><SliderContainer index={11} currentSlide={currentSlide} bgImage="/images/daydreamer_still_12.png"></SliderContainer></Wrap>
          <Wrap><SliderContainer index={12} currentSlide={currentSlide} bgImage="/images/daydreamer_still_13.png"></SliderContainer></Wrap>
          <Wrap><SliderContainer index={13} currentSlide={currentSlide} bgImage="/images/daydreamer_still_14.png"></SliderContainer></Wrap>
          <Wrap><SliderContainer index={14} currentSlide={currentSlide} bgImage="/images/daydreamer_still_15.png"></SliderContainer></Wrap>
          <Wrap><SliderContainer index={15} currentSlide={currentSlide} bgImage="/images/daydreamer_still_16.png"></SliderContainer></Wrap>
          <Wrap><SliderContainer index={16} currentSlide={currentSlide} bgImage="/images/daydreamer_still_17.png"></SliderContainer></Wrap>
          <Wrap><SliderContainer index={17} currentSlide={currentSlide} bgImage="/images/daydreamer_still_18.png"></SliderContainer></Wrap>
          <Wrap><SliderContainer index={18} currentSlide={currentSlide} bgImage="/images/daydreamer_still_19.png"></SliderContainer></Wrap>
          <Wrap><SliderContainer index={19} currentSlide={currentSlide} bgImage="/images/daydreamer_still_20.png"></SliderContainer></Wrap>
    
           </Carousel>
 
        </HeroBlock>
        <HeroRight>
        <img src="/images/HeroRight.png" />
          </HeroRight>
      </BodyContainer>
    
      </Container>
    </>
  );
}

const SliderContainer = styled.div`
  background-repeat: no-repeat;
  background-size: cover;
  height: 450px;
  margin: 10px;
  border-radius: 12px;
  opacity: 0.1;
  transform: scale(.80);
  transition: opacity 1s, transform 1s;
  box-shadow: 2px 2px 10px 2px black; 
  ${props => props.index === props.currentSlide && css`
    opacity: ${props.index === props.currentSlide ? 1 : 0.1};
    transform: scale(${props.index === props.currentSlide ? 1 : 0.8});
  `}

  ${props => props.bgImage && css`
    background-image: url(${props.bgImage});
  `}
`;

const Carousel = styled(Slider)`

  margin-top: 20px;
  padding-bottom:200px;
  & > button {
    opacity: 0;
    height: 100%;
    width: 5vw;
   
  
    &:hover {
      opacity: 1;
      transition: opacity 0.2s ease 0s;
    }
  }

  ul li button {
    &:before {
      font-size: 10px;
      color: rgb(150, 158, 171);
    }
  }

  li.slick-active button:before {
    color: white;
  }

  .slick-list {
    overflow: initial;
  }

  .slick-prev {
    left: -75px;
  }

  .slick-next {
    top:240px;
    right: -110px;
  }

  @media (max-width:800px)
  {
    max-width:400px;
    overflow:hidden;
  }
`;

const Wrap = styled.div`

`;
const HeroLeft =styled.div`
opacity: 0;
z-index:0;
margin:0px -10px 0px 0px;
animation: move-fade-left .5s .25s ease-in-out;
animation-fill-mode: forwards;
@keyframes move-fade-left {
  0% {
    opacity: 0;
    transform: scale(150%) translateX(20%);
  }
  100% {
    opacity: 1;
    transform: scale(150%) translateX(-10%);
  }
}
@media (max-width:800px)
{
  display:none;
}
`;
const HeroRight =styled.div`
opacity: 0;
margin:0px 0px 0px 30px;
transform: scale(110%);
animation: move-fade-right .5s .25s ease-in-out;
animation-fill-mode: forwards;
@keyframes move-fade-right {
  0% {
    opacity: 0;
    transform: scale(120%) translateX(-30%);
  }
  100% {
    opacity: 1;
    transform: scale(120%) translateX(10%);
  }
}
@media (max-width:800px)
{
  display:none;
}
`;
const Container = styled.div`
  position: absolute;
  //background-image:url("/images/RS_TILE.png");

  z-index: -2;
  width: 100vw;
  height: 3200px;
  @media (max-width:800px)
  {
    width:96vw;
    overflow:hidden;
  }
`;

const Background = styled.div`
background-color: rgb(5,5,5);
  position: fixed;
  top:0;//50px;
  opacity: 1;
  z-index: -3;

  width: 100%;
  height: 100%;
  
  filter: ${({ scrollPos }) => `blur(${Math.min(scrollPos / 5, 30)}px) brightness(${ Math.max(100-scrollPos/5,40)}%)`};
  video { 
    width: 100%;
  }

  @media (max-width:800px)
  {
    width:100vw;
    overflow:hidden;
  }
`;
const BodyContainer = styled.div`
position:relative;
  z-index:-2;
  display: flex;
  align-items: center;
  justify-content: center;
  
  margin-top:200px;
  top: 100px;
  padding:0px;
  bottom: 0;
  left: 0;
  right: 0;

@media (max-width:800px)
{
  p{
    text-align:left;
    left:0;
    margin:auto;
 
    padding: 10px 0;
    justify-conent:left;
    width:330px;
  }
  img
  {
    text-align:left;
    left:0;
    
    padding:10px;
    justify-conent:left;
    width:330px;

  }
  iframe
  {
    width:330px;
  }
  max-width:100vw;
 
  left:-30px;
}

`;
const HeroBlock = styled.div`
z-index:-2;
width: 800px;
height:450px;
img{
  width:120%;
  position: center;
  transform: translateX(-8%);
}
  iframe{
    box-shadow: 2px 2px 10px 2px #333; 
    background-color: black;
    border: 2px solid rgb(255, 255, 255, .25);
    position: center;
    border-radius: 12px;
    width:800px;
    height:450px;
    box-shadow: rgb(0 0 0 / 69%) 0px 26px 30px -10px,
    rgb(0 0 0 / 73%) 0px 16px 10px -10px;
  }

 @media (max-width:800px)
 {
  width:330px;

  img
  {
    padding:20px 0;
    width:140%;
  }

  iframe
  {
    width:330px;
    height:200px;
  }
 }
`;

export default Daydreamer;