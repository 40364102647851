import React, { useState } from 'react';
import styled,{ css }  from 'styled-components';
import { Helmet } from "react-helmet";
import GetSlogsData from './GetSlogData';
import WhatsSlogs from './WhatsSlog';

const Slogs = () => {

  const [mode,setMode]=useState("base");

  return (
    <>
     <Helmet>
    
        <title>Slogs</title>
        <meta name="description" content="Slogs: an NFT metaverse of wild biosynthetic mutant monsters on the Solana blockchain. Play the live beta, an action RPG with staking. Enter a world of craziness in this one-of-a-kind experience." />
        <link rel="canonical" href ="/slogs" />
       
    </Helmet>
   
    <BackgroundContainer>  
    </BackgroundContainer> 
     <MasterWrap>
      <BodyContainer>
        <Logo>
        
        <img src="images/SlogsShadow.png" alt="SlogsLogo"></img>
       
        </Logo>

        <SlogsMenu>
        <SlogMenuButton mode={mode} thisMode={"base"}> <button  onClick={() => setMode("base")}>WuzSlog?</button></SlogMenuButton>
        <SlogMenuButton mode={mode} thisMode={"charts"}><button   onClick={() => setMode("charts")}>SlogGraph?</button></SlogMenuButton>
        <a href="https://nft.rolandstudios.com/mint/"> <SlogMenuButton mode={mode} thisMode={"mint"}> <button  onClick={() => setMode("mint")}>WenMINT?</button></SlogMenuButton></a>
        </SlogsMenu>
        <RowContainer>
       
        <SlogoHeroMovie>
        <iframe src="https://player.vimeo.com/video/752140879?controls=0&autoplay=1&playsinline=1&color&autopause=0&loop=1&muted=1&title=1&portrait=1&byline=1#t="
        frameBorder="0"></iframe>
        </SlogoHeroMovie>

        <InfoContainerRight>
         
        <h1>Welcome to the world of Slogs!</h1>
        <p>Here, you'll find a land filled with bio synths, degen chuds, and all sorts of SLOG-inspired creatures. These mischievous beings were spawned by the millions, each longing to walk and hodl the earth once more.</p>
        <PlayTheGame>
          <a href="https://nft.rolandstudios.com/slogs/"><h1>Play Now</h1></a>
        </PlayTheGame>
        
        </InfoContainerRight>
        </RowContainer>
        
        <RenderMode mode={mode} />
      
      </BodyContainer>
      </MasterWrap>
    </>
  );
};


function RenderMode(props) {
  switch (props.mode) 
  {
    case "base":
      return <WhatsSlogs />;
    
    case "charts":
      return <GetSlogsData />;

    case "mint":
      return <GetSlogsData />;
    
  }
}

const MasterWrap = styled.div`
position:relative;
height:5200px;
width:96vw;
overflow:hidden;
`;

const SlogMenuButton = styled.div`
border-radius:4px;
border: ${props => props.mode === props.thisMode ? '1px solid rgba(255,255,255,.5)' : '0px solid rbga(0,0,,0,0)'};
`;


const SlogsMenu = styled.div`

display:flex;
gap:8px;
postion:relative;

margin-left:390px;
margin-bottom:-20px;


button{
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
  width:200px;
  background-color:rgba(55,55,55,.7);

  
  padding:12px;
  color:rgba(240,240,240);
  font-weight:700;
  font-size:16px;
  //border:0;
  
  border-radius:4px;
  box-shadow: 4px 4px 2px 2px rgba(0,0,0,0.25);;  
  text-shadow: 2px 2px 4px #333;
  border: solid 1px rgba(25,25,25,0);
  :hover 
  {
    //transform: scale(1.03);
    background-color: rgba(225,225,225,.75);
    
  }
}
`;

const Logo = styled.div`
opacity:0;
position:relative;
width:800px;
height:250px;
left:-50px;

@media (max-width:800px)
{
  width:350px;
  height:100px;
  left:-10;  
}

img{
  width:100%;
  height:auto;
}


animation: move-left .5s .25s ease-in-out;
  animation-fill-mode: forwards ;

  z-index:12;
  @keyframes move-left {
  0% {
    opacity: 0;
    transform:  translateX(-15%) ;
  }
  100% {
    opacity: 1;
    transform: translateX(0%) ;
  }


`;



const SlogoHeroMovie = styled.div`
position:relative;
width: 800px;
height:450px;

iframe
{
  width: 100%;
  height:100%;
  border-radius: 12px; 
  box-shadow: 4px 4px 2px 2px rgba(0,0,0,0.25);;  
  border: solid 2px rgba(255,255,255,0.3);
}

@media (max-width:800px)
{
  width: 330px;
height:190px;
}

`;

const BodyContainer =styled.div`
position:relative;
z-index:2;
left:150px;
top:200px;

@media (max-width:800px)
{
  left:15px;
  top:100;
`;


const BackgroundContainer = styled.div`
position:absolute;
  height:5200px;;
  width:100%;
  left:0;
  top:0;
  z-index:-2;
  min-height:3200px;
  animation: move-diagonally 8s linear infinite;
  
 
  
  background: url(/images/checkertile.png) repeat;
  @keyframes move-diagonally {
    from {
      background-position: 0 0;
    }
    to {
      background-position: -600px -600px;
    }
  }
  
`;



const RowContainer = styled.div`
  position:relative;
  display: grid;
  grid-template-columns: repeat(2, 600px);
  grid-gap: 150px; 

  border-radius: 20px;
  background: #3a3a3a;//linear-gradient(to bottom, , #3a3a3a);
  box-shadow: 4px 4px 2px 2px rgba(0,0,0,0.5);; 
  border-radius:8px;
  padding:40px;
  max-width:1000px;
  margin:16px;
  left:70;

    img{
      width: 500px;
      margin-left:-200px;
      height: auto;
      border-radius: 12px; 
      box-shadow: 4px 4px 2px 2px rgba(0,0,0,0.25);;  
      border: solid 2px rgba(255,255,255,0.3);
    } 
  
@media (max-width:800px)
{
  width:350px;
  padding:10px;
  margin:0;
  left:0;
  grid-gap: 32px; 
  grid-template-columns: repeat(1, 0px);
  justify-items: left;
}

`;

const InfoContainerRight = styled.div`

background-color: rgba(88,88,88,.75);
border-radius: 20px;
clip-path: polygon(0 0, 100% 0, 100% 90%, 30% 100%, 0 90%);
overflow: auto;
color: #FFFFFF; /* white */ 
width: 600px;
height:500px;
font-size:20px;
font-weight:100;
margin-left:100px;


box-shadow: 10px 10px 20px #888888;
border-radius:8px;
padding:40px;
h1{
  color:yellow;
  text-shadow: 2px 2px 4px #333;
}

@media (max-width:800px)
{
  margin:0;
  padding:10px;
  padding-bottom:80px;
  width:330px;
  height:auto;
}
`;



const PlayTheGame = styled.div`
width:250px;
height:80px;
background-color: rgba(25,25,25,.5);
box-shadow: 4px 4px 2px 2px rgba(0,0,0,0.5);
border-radius: 8px;


h1{
  color: white;
  text-align:center;
  margin:0px;
}

padding: 16px;  
transition: transform 0.3s ease-in-out;
border: solid 1px rgba(255,255,255,0.5);

:hover {
  transform: scale(1.03);
  background-color: rgba(255,255,255,.5);
  color: rgba(25,25,25,1);
  border: solid 1px white;
}
`;



export default Slogs;