import React, { useRef, useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { Helmet } from "react-helmet";

const Store = () => {
  return (
    <>
    <Helmet>
     
      <title>Store</title>
      <meta name="description" content="Rolandstudios: your one-stop destination for the hottest Steam and PlayStation games. Explore a wide range of titles from action-packed shooters to thrilling adventure games. Get exclusive discounts and access to the latest releases with our easy-to-use online store. Start your gaming journey now with Rolandstudios." />
      <link rel="canonical" href ="/store" />
     
  </Helmet>
    <Container>  
      <BodyContainer>
        <h1>GAME STORE</h1>
        <a href="https://store.playstation.com/en-us/product/UP0005-CUSA04769_00-DAYDREAMERAWAKEN">
        <ItemPanel>
          <Icon src="/images/Daydreamer_cap.jpg" />
          <Description>
          <h2>Daydreamer: Awakened Edition</h2>
          <h3>Playstation 4 (July 2016)</h3>
          </Description> 
        </ItemPanel>
        </a>
        <a href="https://store.steampowered.com/app/394390/Daydreamer_Awakened_Edition/">
        <ItemPanel>
          <Icon src="/images/Daydreamer_cap.jpg" />
          <Description>
          <h2>Daydreamer: Awakened Edition</h2>
          <h3>Steam (Aug 21 2015)</h3>
          </Description> 
        </ItemPanel>
        </a>
        <a href="https://store.steampowered.com/app/1594960/Daydreamer_2/">
        <ItemPanel>
          <Icon src="/images/Daydreamer2_cap.jpg" />
          <Description>
          <h2>Daydreamer 2</h2>
          <h3>Steam (TBA 2023)</h3>
          </Description> 
        </ItemPanel>
        </a>
      </BodyContainer>
      
    </Container> 
    </>
  );
};
const Container = styled.div` 
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  justify-content: center; 
  //align-items: center; 
  height: 100vh;
  background: 
  linear-gradient( 45deg, rgba(25,25,25,.5) 25%, transparent 25%, transparent 50%, rgba(25,25,25,.5) 50%, rgba(25,25,25,.5) 75%, transparent 75%, transparent ); 
  background-size: 100px 100px; 
  animation: scroll-bg 2s linear infinite; 
  @keyframes scroll-bg { 
    from 
    {
       background-position: 100px ;
    } 
    to 
    {
      background-position: 0px; 
    } 
  }
  `;

const BodyContainer = styled.div`
position:relative;
left:70px;
top:200px;

  background-color: rgba(25,25,25,.75);
  box-shadow: 4px 4px 2px 2px rgba(0,0,0,0.25);; 
  border-radius:8px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  align-items: center;
  padding:25px;
  max-width: 800px;
  height: 500px;
 

  h1{
    color:yellow;
    margin:20px;
  }

  @media (max-width:800px)
  {
    max-width:330px;
    left:16px;
    top:100px;
  }
`;

const ItemPanel = styled.div`
  background-color: rgba(25,25,25,.5);
  box-shadow: 4px 4px 2px 2px rgba(0,0,0,0.25);
  border-radius: 8px;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto;
  align-items: center;
  margin: 8px 16px;
  padding: 16px;  
  transition: transform 0.3s ease-in-out;
  border: solid 1px rgba(25,25,25,0.5);
  

  :hover {
    transform: scale(1.03);
    background-color: rgba(255,255,255,.5);
    color: rgba(25,25,25,1);
    border: solid 1px white;
  }

  @media (max-width:800px)
  {
    margin: 2px 4px;
    padding: 8px;
  }
`;

const Icon = styled.img`
  width: auto;
  height: auto;
`;

const Description = styled.span`
  font-size: 16px;
  margin:0px;
  margin-left: 16px;
  line-height:.5;
  padding:0px;
  h2{
    font-size:18px;
    font-weight:500;
  }
  h3{
    font-size:12px;
    font-weight:200;
  }

  @media (max-width:800px)
  {
    line-height:1;
    h2{
      //white-space: nowrap;
      font-size:12px;
    }

    h3{
      //white-space: nowrap;
      font-size:8px;
    }
  }
`;


export default Store;