import { useState,useEffect } from "react";
import styled, { css } from 'styled-components';
import React from "react";

const Header = (props) => {

/*
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setScreenWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  */
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(!isOpen);
  }

  const Button = styled.button`;
  background-color: transparent;
  border: none;
  `

const Menu = styled.div`
  position:fixed;
  z-index:12;
  top: 0px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color:  rgba(0,0,0,.75);
  backdrop-filter: blur(64px);
  display: ${({ isOpen }) => (isOpen ? "block" : "none")};
`;

const MenuItem = styled.div`
  padding: 16px 38px;
  text-align: right;
  font-size: 24px;
  font-weight:300;
  color: white;
  text-shadow: 4px 4px 10px rgb(0,0,0,1);
  
  &:hover span {
    transform: scale(110%);
    color: yellow;
    text-shadow: 1px 1px 2px rgb(255,255,255,.25);
  }
`;

const MenuItemText = styled.span`
  text-align: right;
  display: inline-block;
  transition: transform 0.2s ease;
`;

  return (
    <>
     {/*<ScreenWidth>W:{screenWidth} </ScreenWidth>*/}
    <Hamburger onClick={handleClick} isOpen={isOpen}>
      {isOpen?
        <img src="/images/x-icon.png" class="filter-green" alt="Menu" />  
        :
        <img src="/images/burger.png" class="filter-green" alt="Menu" />  }
    </Hamburger>  
      {
      <Menu isOpen={isOpen}>
        <br></br><br></br><br></br>
        <a href="/"><MenuItem><MenuItemText>Home</MenuItemText></MenuItem></a>
        <a href="/daydreamer"> <MenuItem><MenuItemText>Daydreamer</MenuItemText></MenuItem></a>
        <a href="/daydreamer2"><MenuItem><MenuItemText>Daydreamer2</MenuItemText></MenuItem></a>
        <a href="/flipperfrenzy"><MenuItem><MenuItemText>FlipperFrenzy</MenuItemText></MenuItem></a>
        <a href="/slogs"><MenuItem><MenuItemText>Slogs NFT</MenuItemText></MenuItem></a> 
        <a href="/demonblaster"><MenuItem><MenuItemText>DemonBlaster</MenuItemText></MenuItem></a> 
        <a href="/store"> <MenuItem><MenuItemText>GameStore</MenuItemText></MenuItem></a>
        <a href="/about"> <MenuItem><MenuItemText>About</MenuItemText></MenuItem></a>
        <a href="/contact"> <MenuItem><MenuItemText>Contact</MenuItemText></MenuItem></a>
        <MenuItem>
        <SocialMedia>
        <a href="https://www.facebook.com/Rolandstudiosofficial/">
        <img src="/images/social/facebook.svg" alt="Facebook"/>
        </a>
        <a href="https://twitter.com/roland_studios">
        <img src="/images/social/twitter.svg" alt="Twitter"/>
        </a>
        <a href="https://www.instagram.com/rolandstudiosofficial/">
        <img src="/images/social/instagram.svg" alt="Instagram"/>
        </a>
        <a href="https://www.youtube.com/channel/UCN_SWgEDQtgDQE3o0l_CQxQ">
        <img src="/images/social/youtube.svg" alt="Youtube"/>
        </a>
        <a href="https://discord.gg/G9MVnBpzTg">
        <img src="/images/social/discord.svg" alt="Discord"/>
        </a>
        {/*
        <a href="https://www.linkedin.com/in/roland-womack-8b9a397/">
        <img src="/images/social/linkedin.svg" alt="Linkedin"/>
        </a>
       
        <a href="https://www.twitch.tv/rolandstudios">
        <img src="/images/social/twitch.svg" alt="Twitch"/>
        </a>
        */}
        </SocialMedia>
       </MenuItem>
      </Menu>
  }
    <Nav>    
      <Logo>
        <a href = "/">
        <img src="/images/rolandstudioslogo.png"  />
        </a>
      </Logo>
     
          <NavMenu>
            <a href="/">
              <img src="/images/pixelicon/home-ex.png" alt="home"  />
              <span>HOME</span>
            </a>  
            <a href="/daydreamer">
              <img src="/images/pixelicon/ammo-ex.png" alt="daydreamer" />
              <span>DAYDREAMER</span>
            </a>
            <a href="/daydreamer2">
              <img src="/images/pixelicon/health-empty-ex.png" alt="daydreamer2"  />
              <span>DAYDREAMER2</span>
            </a>
            <a href="/flipperfrenzy">
              <img src="/images/pixelicon/fish-ex.png" alt="flipperfrenzy"  />
              <span>FLIPPERFRENZY</span>
            </a>
            <a href="/slogs">
              <img src="/images/pixelicon/chart-ex.png" alt="slogs"  />
              <span>SLOGS NFT</span>
            </a>
            <a href="/demonblaster">
              <img src="/images/pixelicon/skull-ex.png" alt="demonblaster"  />
              <span>DEMONBLASTER</span>
            </a>
            <a href="/store">
              <img src="/images/pixelicon/shop-ex.png" alt="store"  />
              <span>GAME STORE</span>
            </a>
            <a href="/about">
              <img src="/images/pixelicon/question-ex.png" alt="about" />
              <span>ABOUT</span>
            </a>
            <a href="/contact">
              <img src="/images/pixelicon/mail-ex.png" alt="contact" />
              <span>CONTACT</span>
            </a>
            
          </NavMenu>   
    </Nav>
    </>
  );
};


const ScreenWidth =styled.div`
position:fixed;
z-index:50;
top:100px;
left:100px;
`;

const Hamburger = styled.button`
cursor: pointer;
background-color: transparent;
border: none;
position: fixed;
top:14px;
right: 24px;
border: none;
z-index:15;

&:hover {
  transform: scale(1.1); 
    color: yellow;
    text-shadow: 2px 2px 4px rgb(255,255,255,.25);
    filter:  invert(0%) sepia(29%) saturate(5489%) hue-rotate(2deg) brightness(200%) contrast(76%);
}

img
{
  width:20px;
  //filter:  ${props => (props.isOpen?  '' : 'invert(0%) sepia(29%) saturate(5489%) hue-rotate(2deg) brightness(94%) contrast(76%)')};
}
`;




const Logo = styled.a`
  padding: 0;
  max-width: 120px;
  width: 100%; 
  margin-top: 2px;
  margin-right:0px;
  padding-left:8px;
  img{
    height:40px;
  }
  z-index: 12;
 
`;

const Nav = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 50px;
  background-color: rgba(255,255,255,0.05);  /* translucent white background */
  backdrop-filter: blur(8px); /* blurs background */
  border: 1px solid rgba(255,255,255,0.1);
  display: flex;

  font-weight:200;
  z-index: 11;
`;

const NavMenu = styled.div`
  align-items: center;
  display: flex;
  gap:0px;
  white-space: nowrap;
  flex-flow: row nowrap;
  height: 100%;
  justify-content: flex-end;
  margin: 0px;
  padding: 0px;
  position: relative;
  margin-right: auto;
  margin-left: 70px;
  margin-top: 3px;
  font-size: 10px; 
  a {
    display: flex;
    align-items: center;
    padding: 0 12px;

    transition: all 0.2s ease-in-out;
    &:hover {
      transform: scale(1.05); 
      img {
        filter: invert(0%) sepia(29%) saturate(5489%) hue-rotate(3deg) brightness(100%) contrast(76%);
      }
      span {
        color: yellow;
        text-shadow: 0 0 10px rgb(255,255,255,.3);
      }
    }
    img {
      height: 20px;
      width: 20px;
      min-width: 8px;
      min-height:8px;
     
      transition: all 0.2s ease-in-out;
    }
    span {
      color:  #f9f9f9;
      //font-size: 10px;
      letter-spacing: 1.42px;
      
    }
  }

  @media (max-width: 1300px) {
    margin-left: 60px;
    a {
      padding: 0 8px;
    }
    //transform: scale(.9);
  }

  @media (max-width: 1200px) {
    margin-left: 60px;
    a {
      padding: 0 6px;
    }
    //transform: scale(.9);
  }

  
  @media (max-width: 1100px) {
    margin-left: 60px;
    a {
      padding: 0 4px;
    }
    //transform: scale(.9);
  }

  
  @media (max-width: 1200px) {
    margin-left: 60px;
    a {
      padding: 0 12px;
    }
    img {
      display:none;
    }
    //transform: scale(.9);
  }

  @media (max-width: 1100px) {
    margin-left: 60px;
    a {
      padding: 8px;
    }
    img {
      display:none;
    }
    //transform: scale(.9);
  }

  @media (max-width: 1000px) {
    margin-left: 60px;
    a {
      padding: 0 4px;
    }
    img {
      display:none;
    }
  }

  @media (max-width: 900px) {
    margin-left: 60px;
 
    a {
      padding: 0 4px;
    }

    img {
      display:none;
    }
    
    span {
      font-size: 8px;    
    }
  }

  @media (max-width: 800px) {
    display:none;
  }
  

 
`;



const SocialMedia = styled.div` 
display: flex;
flex-wrap:wrap;


z-index:12;
justify-content: flex-end;
width: 100%;

padding: 10px 0px;
margin-right-5px;

  a { 
    color: white;
    margin: 0 5px;
    font-size: 8px; 
    transition: all 0.2s 
    ease-in-out; &:hover
    { 
        transform: scale(1.1); 
    } img { 
      width: 24px; 
      height: 24px; 
      margin-right: -5px;
      margin-left:10px; 
      } 
  }`;




export default Header;
