import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import db from "../firebase";
import axios from 'axios';
import { Helmet } from "react-helmet";

const About = (props) => {

  const [videos, setVideos] = useState([]);
  const CHANNEL_ID = '1829111';
  const ACCESS_TOKEN = "969461dcba991cef0b33943a5386a6a0";
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const fetchVideos = async () => {
      const response = await axios.get(`https://api.vimeo.com/channels/${CHANNEL_ID}/videos`, {
        headers: {
          'Authorization': `Bearer ${ACCESS_TOKEN}`,
          'Content-Type': 'application/json'
        }
      });
      setVideos(response.data.data);
    }
    fetchVideos();
  }, []);

  let settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    autoplaySpeed: 1000,
    slidesToShow: 3.7,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    rtl:true,
  };

  const [scrollPos, setScrollPos] = useState(0);
  useEffect(() => { 
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  const handleScroll = () => {
    setScrollPos(window.scrollY);
  };

  const blur = Math.min(scrollPos / 10, 10);
  const bgStyle = 
  { 
    filter: `blur(${blur*1.5}px) brightness(${(100-blur*3)}%)` 
  };

 
  
    return (

      <>
    <Helmet>
      
        <title>About</title>
        <meta name="description" content="Discover the story behind RolandStudios - a solo indie game developer located in Bethesda, MD. Learn about his background and experience in visual effects and how it helps him bring his own game ideas to life. Explore the website to discover the latest games developed by RolandStudios and get an insight into the projects he is currently building." />
        <link rel="canonical" href ="/about" />
      
    </Helmet>

      <BackgroundTile scrollPos={scrollPos}>
      </BackgroundTile>  
      <Background scrollPos={scrollPos}>
          <img src="/images/About.jpg" />
        </Background>
    
     <BodyContainer>
      <h1>ABOUT</h1>
      <BioContainer>
      <img src="/images/biopic.jpg" alt="RolandBioPic"/>
      <div>
      <p>
      Hello, my name is Roland and I am the creator of RolandStudios, a solo indie game developer located in Bethesda, MD. From a young age, I have always been drawn to the creative arts, using drawing and animation as a way to connect with others. As a lifelong gamer, I was inspired to take my passion to the next level and honor the craft I love by developing games myself.
      <br></br><br></br>
      I received my undergraduate degree from the School of Visual Arts in New York City and started my career in visual effects. Over ten years in the industry, I have worked on a wide range of projects, both large and small. This experience has given me the knowledge and expertise to bring my own game ideas to life.
      <br></br><br></br>
      Halfway through my career, as gaming engines became more accessible, I made a pivot in my career to pursue game development, which started with my first game Daydreamer.
      <br></br><br></br>
      RolandStudios was originally launched in 2003 as a repository for my animation portfolio. The first iteration was built in flash and the site continued to evolve with my personal and professional work throughout the years. Today, the focus of the site is to curate the games I am developing, but not only to market them, but also to give insight into the projects I am currently building.
      <br></br><br></br>
      This website is a reflection of my long journey from artist to developer and I am excited to share my creations with the world.
      </p>
      </div>
      </BioContainer>
      <br></br>
      <h1>DEV LOG</h1>
      <FullTextPanel>
        <p>
        Here, you'll get an inside look at the development process of our upcoming indie game, Daydreamer2. This channel will feature videos of our work in progress, key milestones, and challenges we face as we bring this game to life.
        </p>
      </FullTextPanel>
      <br></br>
      <PlayListContainer >
          
          {videos.slice(0, 4).map((video) => (
            <iframe 
              key={video.uri} 
              src={`https://player.vimeo.com/video/${video.uri.split('/').pop()}?controls=1&autoplay=1&playsinline=0&color&autopause=0&loop=1&muted=0&title=0&portrait=1&byline=1#t=`}
              frameBorder="0"
              allowFullScreen
            />
          ))}
          
        </PlayListContainer>
       
        </BodyContainer>
      {/*<Overlay id="width-overlay">Current Width: <span id="width-value"></span>px</Overlay>*/}
   </>
    );
  };

const BodyContainer = styled.div`
position:relative;
top:200px;
padding-bottom:200px;
left:70px;
z-index:2;

h1{
  color:yellow;
}


@media (max-width:800px)
{
  width:340px;
  overflow:hidden;
  left:10px;
  h1
  {
    margin-left:10px;
  }
}
`;

  const FullTextPanel = styled.div`
  position:relative;
  background-color: rgba(25,25,25,.75);
  box-shadow: 4px 4px 2px 2px rgba(0,0,0,0.5);; 
  border-radius:12px;
  padding:20px 60px;

  display: flex; 
  justify-content: center; 
  align-items:center;
  max-width:1000px;
  min-height:50px;
  height:auto;
  z-index:3;

  @media (max-width:800px)
  {
    flex-wrap: wrap;
    padding: 8px 16px ;
    max-width:330px;
    p{
      max-width:330px;
      margin: 32px 0;
    }
  }

  `;

  const BioContainer = styled.div`
  position: relative;
  display:flex;

  background-color: rgba(25,25,25,.75);
  box-shadow: 4px 4px 2px 2px rgba(0,0,0,0.5);; 
  border-radius:12px;
  padding:60px;
  
  max-width:1000px;
  z-index:2;

  p{
    max-width:600px;
    margin:0 0 0 30px;
  }

  img{
    min-width:300px;
    height:100%;
    box-shadow: 4px 4px 2px 2px rgba(0,0,0,0.5);; 
    border-radius:12px;
  }

  @media (max-width:800px)
  {
    flex-wrap: wrap;
    padding:16px;
    max-width:330px;
    p{
      max-width:330px;
      margin: 32px 0;
    }
  }

  `;

const PlayListContainer = styled.div`
postion: relative;

background-color: rgba(25,25,25,.75);
box-shadow: 4px 4px 2px 2px rgba(0,0,0,0.5);; 
border-radius:12px;
padding:32px;

max-width:1000px;
z-index:2;
display:grid;
gap:30px;
grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));
justify-content: center; /* center items horizontally */
align-items: center; /* center items vertically */

iframe {
  min-width: 330px;
  min-height: 190px;
  border-radius: 12px; 
  box-shadow: 4px 4px 2px 2px rgba(0,0,0,0.25);;  
  border: solid 2px rgba(255,255,255,0.3);
}

@media (max-width:800px)
{
  max-width:400px;
}

`;


const BackgroundTile = styled.div`

filter: ${({ scrollPos }) => `blur(${Math.min(scrollPos / 5, 30)}px) brightness(${ Math.max(100-scrollPos/5,40)}%)`};
  height:100vh;
  width:100vw;
  min-height:3200px;
  opacity:1;
  animation: move-diagonally 8s linear infinite;
  z-index:-1;
  position:fixed;
  top:-1000px;
  background: url(/images/checkertile.png) repeat;
  @keyframes move-diagonally {
    from {
      background-position: 0 0;
    }
    to {
      background-position: -600px -600px;
    }
  }
`;

const Background = styled.div`
  position: fixed;
  opacity: 1;
  z-index: 1;
  top:0;
  height:100vh;
  width:100vw;

  filter: ${({ scrollPos }) => `blur(${Math.min(scrollPos / 5, 30)}px) brightness(${ Math.max(100-scrollPos/5,40)}%)`};
  img { 
    box-shadow: 4px 4px 2px 2px rgba(0,0,0,0.5);; 
    width:100vw;
    height:auto;
  }
`;
  












  
  export default About;