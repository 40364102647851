import React, { useRef, useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import axios from 'axios';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";


const WhatsSlogs = () => {

  const [videos, setVideos] = useState([]);
  const CHANNEL_ID = '1828389';
  const ACCESS_TOKEN = "969461dcba991cef0b33943a5386a6a0";
  const [currentSlide, setCurrentSlide] = useState(0);


  useEffect(() => {
    const fetchVideos = async () => {
      const response = await axios.get(`https://api.vimeo.com/channels/${CHANNEL_ID}/videos`, {
        headers: {
          'Authorization': `Bearer ${ACCESS_TOKEN}`,
          'Content-Type': 'application/json'
        }
      });
      setVideos(response.data.data);
    }
    fetchVideos();
  }, []);

  let settings1 = {
    dots: false,
    infinite: true,
    speed: 1000,
    autoplaySpeed: 1000,
    slidesToShow: 3.7,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    rtl:true,
  };
  let settings2 = {
    dots: false,
    infinite: true,
    speed: 1000,
    autoplaySpeed: 1000,
    slidesToShow: 3.7,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    rtl:false,
  };

  
  return (    
        
<>
        <StoreContainer>
        <h1>Happy Collector</h1>
        <p>(Secondary Stores):</p>
        </StoreContainer>
        <CallToActionContainer>
 

      <a href='https://magiceden.io/marketplace/hpycol'> <img src="/images/MagicEden.png"></img> </a>
      <a href='https://opensea.io/collection/happy-collectors'><img src="/images/OpenSea.png"></img></a>
      <a href='https://solsea.io/c/61fb83c96f3a3843caa95b3e'><img src="/images/SolSea.png"></img> </a>
      </CallToActionContainer>
            <CarouselContainer>
            <Carousel 
          {...settings1} 
          beforeChange={(oldIndex, newIndex) => setCurrentSlide(newIndex)}
          afterChange={(index) => setCurrentSlide(index)}
        >
          <Wrap><SliderContainer index={0} currentSlide={currentSlide} bgImage="/images/MrGrin/1.png"></SliderContainer></Wrap>
          <Wrap><SliderContainer index={1} currentSlide={currentSlide} bgImage="/images/MrGrin/2.png"></SliderContainer></Wrap>
          <Wrap><SliderContainer index={2} currentSlide={currentSlide} bgImage="/images/MrGrin/3.png"></SliderContainer></Wrap>
          <Wrap><SliderContainer index={3} currentSlide={currentSlide} bgImage="/images/MrGrin/4.png"></SliderContainer></Wrap>
          <Wrap><SliderContainer index={4} currentSlide={currentSlide} bgImage="/images/MrGrin/5.png"></SliderContainer></Wrap>
          <Wrap><SliderContainer index={5} currentSlide={currentSlide} bgImage="/images/MrGrin/6.png"></SliderContainer></Wrap>
          <Wrap><SliderContainer index={6} currentSlide={currentSlide} bgImage="/images/MrGrin/7.png"></SliderContainer></Wrap>
          <Wrap><SliderContainer index={7} currentSlide={currentSlide} bgImage="/images/MrGrin/8.png"></SliderContainer></Wrap>
          <Wrap><SliderContainer index={8} currentSlide={currentSlide} bgImage="/images/MrGrin/9.png"></SliderContainer></Wrap>
          <Wrap><SliderContainer index={9} currentSlide={currentSlide} bgImage="/images/MrGrin/10.png"></SliderContainer></Wrap>
          </Carousel>
          </CarouselContainer>

      
          <CarouselContainer>
      <Carousel 
          {...settings2} 
          beforeChange={(oldIndex, newIndex) => setCurrentSlide(newIndex)}
          afterChange={(index) => setCurrentSlide(index)}
        >
          <Wrap><SliderContainer index={0} currentSlide={currentSlide} bgImage="/images/HappyCollector/1.png"></SliderContainer></Wrap>
          <Wrap><SliderContainer index={1} currentSlide={currentSlide} bgImage="/images/HappyCollector/2.png"></SliderContainer></Wrap>
          <Wrap><SliderContainer index={2} currentSlide={currentSlide} bgImage="/images/HappyCollector/3.png"></SliderContainer></Wrap>
          <Wrap><SliderContainer index={3} currentSlide={currentSlide} bgImage="/images/HappyCollector/4.png"></SliderContainer></Wrap>
          <Wrap><SliderContainer index={4} currentSlide={currentSlide} bgImage="/images/HappyCollector/5.png"></SliderContainer></Wrap>
          <Wrap><SliderContainer index={5} currentSlide={currentSlide} bgImage="/images/HappyCollector/6.png"></SliderContainer></Wrap>
          <Wrap><SliderContainer index={6} currentSlide={currentSlide} bgImage="/images/HappyCollector/7.png"></SliderContainer></Wrap>
          <Wrap><SliderContainer index={7} currentSlide={currentSlide} bgImage="/images/HappyCollector/8.png"></SliderContainer></Wrap>
          <Wrap><SliderContainer index={8} currentSlide={currentSlide} bgImage="/images/HappyCollector/9.png"></SliderContainer></Wrap>
          <Wrap><SliderContainer index={9} currentSlide={currentSlide} bgImage="/images/HappyCollector/10.png"></SliderContainer></Wrap>
          </Carousel>
          </CarouselContainer>

          <RowContainer>
        <InfoContainerLeft>
        <h1>But that's not all,</h1>
        <p>because Slogs Metaverse is also home to the highly-anticipated project of the same name. A new gaming metaverse built on the Solana blockchain, Slogs is the ultimate destination for all your SLOG-themed needs.</p>
        <br></br>
        <p>When you first log in, you'll be greeted by your personal pfp nft, which will transport you straight to Slogs HQ. Here, you'll find a bustling community of Biosynthetic mutants created by the deliquent AI, "Alegra". Alegra was one of the ruling AI overlords but she was a bit of a dumb one, and in her attempt to recreate human life, she only came up with these deformed monstrosities. And the best part? The game plays right in your browser with WebGl app.
        </p>
        </InfoContainerLeft>
       <ImageListContainer >
    <img src="/images/slogburger.png"></img>
    <img src="/images/sloga2.jpg"></img>
    
    </ImageListContainer>
      </RowContainer>

      <RowContainer>
        <PlayListContainer >
          <div >
          {videos.slice(0, 4).map((video) => (
            <iframe 
              key={video.uri} 
              src={`https://player.vimeo.com/video/${video.uri.split('/').pop()}?controls=0&autoplay=1&playsinline=1&color&autopause=0&loop=1&muted=1&title=1&portrait=1&byline=1#t=`}
              frameBorder="0"
              allowFullScreen
            />
          ))}
          </div>
        </PlayListContainer>
     

          <InfoContainerRight>
        
<p>
But what's a metaverse without exciting features? In Slogs, you'll find a variety of activities to keep you entertained, such as:
<li>Slog Karts for high-speed racing</li>
<li>Pvp Arena for some friendly competition</li>
<li>Live Slog shows for some entertainment</li>
<li>Sloga Spa for relaxation</li>
<li>Slog baths for some much-needed rejuvenation</li>
<li>McWage Burger's for satisfying your hunger</li>
<li>staking for earning some rewards</li>
<p>So come join the fun and become a part of the Slogs community. Who knows? You might just become the next SLOG master!"</p>
</p>
        </InfoContainerRight>
      </RowContainer>
      
    </>
  );
};
const Wrap = styled.div`

`;

const MasterWrap = styled.div`
position:relative;
height:5200px;
width:96vw;
overflow:hidden;
`;

const SliderContainer = styled.div`
  background-repeat: no-repeat;
  background-size: cover;
  height: 200px;
  width:200px;
  margin-left: 500px;
  border-radius: 12px;
  opacity: 0.5;
  transform: scale(.80);
  transition: opacity 1s, transform 1s;
  box-shadow: 2px 2px 10px 2px black; 
  ${props => props.index === props.currentSlide && css`
    opacity: ${props.index === props.currentSlide ? 1 : 0.1};
    transform: scale(${props.index === props.currentSlide ? 1 : 0.8});
  `}

  ${props => props.bgImage && css`
    background-image: url(${props.bgImage});
  `}
`;

const Carousel = styled(Slider)`
  .slick-list {
    overflow: initial;
  }
`;


const SlogsMenu = styled.div`
display:flex;

gap:8px;

postion:relative;

margin-left:390px;
margin-bottom:-20px;
button{
  transition: transform 0.2s ease-in-out;
  width:200px;
  background-color:rgba(135,135,135,1);
  padding:12px;
  color:rgba(240,240,240);
  font-weight:700;
  font-size:16px;
  border:0;
  border-radius:4px;
  box-shadow: 4px 4px 2px 2px rgba(0,0,0,0.25);;  
  text-shadow: 2px 2px 4px #333;
  
  :hover 
  {
    transform: scale(1.03);

    border: solid 1px rgba(25,25,25,.25);
  }
}
`;

const Logo = styled.div`
opacity:0;
position:relative;
width:800px;
height:250px;
left:-50px;

@media (max-width:800px)
{
  width:350px;
  height:100px;
  left:-10;  
}

img{
  width:100%;
  height:auto;
}


animation: move-left .5s .25s ease-in-out;
  animation-fill-mode: forwards ;

  z-index:12;
  @keyframes move-left {
  0% {
    opacity: 0;
    transform:  translateX(-15%) ;
  }
  100% {
    opacity: 1;
    transform: translateX(0%) ;
  }


`;



const SlogoHeroMovie = styled.div`
position:relative;
width: 800px;
height:450px;

iframe
{
  width: 100%;
  height:100%;
  border-radius: 12px; 
  box-shadow: 4px 4px 2px 2px rgba(0,0,0,0.25);;  
  border: solid 2px rgba(255,255,255,0.3);
}

@media (max-width:800px)
{
  width: 330px;
height:190px;
}

`;

const BodyContainer =styled.div`
position:relative;
z-index:2;
left:150px;
top:200px;

@media (max-width:800px)
{
  left:15px;
  top:100;
`;

const SideVideo =styled.div`
margin-left:  -250px;
display:grid;
grid-gap: 32px; 
iframe 
  {
    
  width: 640px;
  height: 360px;
  border-radius: 12px; 
  box-shadow: 4px 4px 2px 2px rgba(0,0,0,0.25);;  
  border: solid 2px rgba(255,255,255,0.3);
} 
`;

const BackgroundContainer = styled.div`
position:absolute;
  height:5200px;;
  width:100%;
  left:0;
  top:0;
  z-index:-2;
  min-height:3200px;
  animation: move-diagonally 8s linear infinite;
  
 
  
  background: url(/images/checkertile.png) repeat;
  @keyframes move-diagonally {
    from {
      background-position: 0 0;
    }
    to {
      background-position: -600px -600px;
    }
  }
  
`;

const CarouselContainer = styled.div`
  display: grid;
  overflow: hidden;
  grid-template-columns: repeat(2, 700px);
 
  background-color: rgba(25,25,25,.75);
  box-shadow: 4px 4px 2px 2px rgba(0,0,0,0.5);; 
  border-radius:8px;
  max-width:1000px;
 
  height: 200px;
  @media (max-width:800px)
  {
    left:0px;
    margin:0;
    padding:0;
    width:400px;
  }
`;
const StoreContainer = styled.div`
margin:0px 32px;

//display:flex;
//align-items: center; 
h1{
  color:yellow;
}
p{
margin-top:-12px;
}
`;
const CallToActionContainer = styled.div`
display: flex;
position:relative;
justify-content: left;
padding:15px;

@media(max-width:800px)
{
  flex-wrap:wrap;
  left:0;
  margin:0;

}

img{
  
  background-color: rgba(25,25,25,.5);
  box-shadow: 2px 2px 1px 1px rgba(0,0,0,0.5);
  border-radius: 8px;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto;
  align-items: center;
  margin: 8px 16px;
  
  transition: transform 0.3s ease-in-out;
  border: solid 1px rgba(25,25,25,0);

  :hover {
    transform: scale(1.03);
    background-color: rgba(255,255,255,.5);
    color: rgba(25,25,25,1);
    border: solid 1px rgba(25,25,25,.25);
  }
}
`;

const RowContainer = styled.div`
  position:relative;
  display: grid;
  grid-template-columns: repeat(2, 600px);
  grid-gap: 150px; 

  border-radius: 20px;
  background: #3a3a3a;//linear-gradient(to bottom, , #3a3a3a);
  box-shadow: 4px 4px 2px 2px rgba(0,0,0,0.5);; 
  border-radius:8px;
  padding:40px;
  max-width:1000px;
  margin:16px;
  left:70;

    img{
      width: 500px;
      margin-left:-200px;
      height: auto;
      border-radius: 12px; 
      box-shadow: 4px 4px 2px 2px rgba(0,0,0,0.25);;  
      border: solid 2px rgba(255,255,255,0.3);
    } 
  
@media (max-width:800px)
{
  width:350px;
  padding:10px;
  margin:0;
  left:0;
  grid-gap: 32px; 
  grid-template-columns: repeat(1, 0px);
  justify-items: left;
}

`;

const InfoContainerRight = styled.div`

background-color: rgba(88,88,88,.75);
border-radius: 20px;
clip-path: polygon(0 0, 100% 0, 100% 90%, 30% 100%, 0 90%);
overflow: auto;
color: #FFFFFF; /* white */ 
width: 600px;
height:500px;
font-size:20px;
font-weight:100;
margin-left:100px;


box-shadow: 10px 10px 20px #888888;
border-radius:8px;
padding:40px;
h1{
  color:yellow;
  text-shadow: 2px 2px 4px #333;
}

@media (max-width:800px)
{
  margin:0;
  padding:10px;
  padding-bottom:80px;
  width:330px;
  height:auto;
}
`;

const InfoContainerLeft = styled.div`
  width: 600px;
  height:750px;
  font-size: 20px;
  font-weight: 100;
  margin-left: -150px;
  background-color: rgba(100,100,100,.75);
  border-radius: 10px 20px 30px 40px;
  clip-path: polygon(10% 0, 100% 0, 100% 90%, 0 100%, 0 10%);
  color: #FFFFFF;
  padding: 40px;
  position: relative;
  z-index: 1;
  h1 {
    color: yellow;
    text-shadow: 2px 2px 4px #333;
  }
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 10px 20px 30px 40px;
    box-shadow: 2px 2px 5px #888888, -2px -2px 5px #888888;
    z-index: -1;
  }

  @media (max-width:800px)
{
  margin:0;
  padding:16px;
  padding-bottom:100px;
  padding-top:30px;
  width:330px;
  height:auto;
  img
  {
    width:330px;
    height:auto;
  }
}
`;

const PlayTheGame = styled.div`
width:250px;
height:80px;
background-color: rgba(25,25,25,.5);
box-shadow: 4px 4px 2px 2px rgba(0,0,0,0.5);
border-radius: 8px;


h1{
  color: white;
  text-align:center;
  margin:0px;
}

padding: 16px;  
transition: transform 0.3s ease-in-out;
border: solid 1px rgba(255,255,255,0.5);

:hover {
  transform: scale(1.03);
  background-color: rgba(255,255,255,.5);
  color: rgba(25,25,25,1);
  border: solid 1px white;
}
`;

const PlayListContainer = styled.div`
background-color: rgba(35,35,35,.95);
box-shadow: 4px 4px 2px 2px rgba(0,0,0,0.5);; 
border-radius:8px;
padding:40px;
margin: 0 -100px;

display: flex;
space-between:32px;
position:relative;
left: 100px;

iframe {
  width: 360px;
  height: 200px;
  border-radius: 12px; 
  box-shadow: 4px 4px 2px 2px rgba(0,0,0,0.25);;  
  border: solid 2px rgba(255,255,255,0.3);
} 

@media (max-width:800px)
{
  left:0;
  margin:0;
  padding:0;
  flex-wrap: wrap;
  max-width:330px;
  display:grid;

  iframe {
    width: 330px;
    height: 190px;
    border-radius: 12px; 

  } 
}



 

  
`;
const ImageListContainer = styled.div`


img {
  margin:8px;
margin-left:-200px;
  width: 600px;
  height:auto;
  border-radius: 12px; 
  box-shadow: 4px 4px 2px 2px rgba(0,0,0,0.25);;  
  border: solid 2px rgba(255,255,255,0.3);
} 

@media (max-width:800px)
{
  img
  {
    margin:0;
    width:330px;
    height:auto;
  }
}
`


export default WhatsSlogs;