
import React, { useRef, useState, useEffect } from 'react';
import emailjs from '@emailjs/browser';
import styled from "styled-components";
import { Helmet } from 'react-helmet';

const Contact = (props) => {


const [messageSent, setMessageSent] =  useState(false);
const form = useRef()


const sendMessage = (e) => {
  e.preventDefault();
  emailjs.sendForm('service_z1iqchp', 'template_iabqi5x', form.current, 'tJk3SteWZD_wRsIkO')
    .then((result) => {
        console.log(result.text);
    }, (error) => {
        console.log(error.text);
    });
    e.target.reset();
    setMessageSent((prevCheck) =>true );
};



const [scrollPos, setScrollPos] = useState(0);
useEffect(() => { 
  window.addEventListener('scroll', handleScroll);
  return () => {
    window.removeEventListener('scroll', handleScroll);
  };
}, []);


const handleScroll = () => {
  setScrollPos(window.scrollY);
};

  return (
    <>
    <Helmet>
      
        <title>Contact</title>
        <meta name="description" content="Get in touch with RolandStudios - the premier destination for the latest and greatest Steam and PlayStation games. Contact for support, inquiries, and to share your feedback. Always ready to assist." />
        <link rel="canonical" href ="/contact" />
       
    </Helmet>

    <BackgroundTile scrollPos={scrollPos}>
    </BackgroundTile>  
    <Background scrollPos={scrollPos}>
        <img src="/images/About.jpg" />
      </Background>
  
      
     
    <Container>
    <Title>CONTACT</Title>
        <ContactInfo>
          <h1>Roland Womack</h1>
          <h2>Email:</h2>
          <p>roland@rolandstudios.com</p>
          <br></br>
          <h2>Phone:</h2>
          <p>(301) 502 2124</p>

        </ContactInfo>
        <br></br>

        <Title>MESSAGE</Title>
    <ContactForm>
    
      
      <form ref={form} onSubmit={sendMessage}>
   
      <label>*Your Name:</label>
      <br></br>
      <Fields>
      <input type="text" name="name" />
      <br></br>

      <label>*Your Email:</label>
      <br></br>
      <input type="email" name="email" />
      <br></br>

      <label>*Your Message:</label>
      <br></br>
      <textarea name="message" />
    
      <br></br>
      </Fields>
      <Submit>
      <input class="submitStyle"  type="submit" value="Send"/>  
      </Submit>
      {messageSent?
      <div><br></br>[ Message Sent ]</div>
      :<div></div>}
    </form>
    </ContactForm>
 
    </Container>
    </>
  );
}


const BackgroundTile = styled.div`
filter: ${({ scrollPos }) => `blur(${Math.min(scrollPos / 5, 30)}px) brightness(${ Math.max(100-scrollPos/5,40)}%)`};
  height:100vh;
  width:100vw;
  min-height:3200px;
  opacity:1;
  animation: move-diagonally 8s linear infinite;
  z-index:-1;

position:fixed;
 top:-1000px;

  background: url(/images/checkertile.png) repeat;
  @keyframes move-diagonally {
    from {
      background-position: 0 0;
    }
    to {
      background-position: -600px -600px;
    }
  }
`;

const Background = styled.div`

  position: fixed;
  opacity: 1;
  z-index: 1;
  top:0;
  height:100vh;
  width:100vw;

  filter: ${({ scrollPos }) => `blur(${Math.min(scrollPos / 5, 30)}px) brightness(${ Math.max(100-scrollPos/5,40)}%)`};
  img { 
    box-shadow: 4px 4px 2px 2px rgba(0,0,0,0.5);; 
    width:100vw;
    height:auto;
  }
`;
  

const ContactInfo = styled.div`
postion:relative;
max-width:700px;
height:auto;
background-color: rgba(55,55,55,0.75);  /* translucent white background */
border: 1px solid rgba(255,255,255,0.1);
border-radius:12px;
box-shadow: 12px 12px 50px 2px rgba(0,0,0,0.5);; 

padding: 40px 60px;
h1{
  margin: 20px 0 20px 0;
  padding:0;
  font-weight:700;
  font-size:25px;
}
h2{
  margin:0;
  padding:0;
  font-weight:700;
  font-size:20px;
}
p{
  margin:0;
  padding:0;
  font-weight:200;
  font-size:15px;
}

@media (max-width:800px){
  width:330px;
  left:16px;
  padding:16px;
  margin:0;
}

`;
const Container = styled.div`

position: relative;
opacity: 1;
z-index: 1;
min-height:500px;
max-width:800px;

left:70px;
top:200px;
height:100vh;;

@media (max-width:800px){
  left:16px;
  width:330px;
  padding:0;
  margin:0;
  top:100px;
}
`;


const ContactForm = styled.div`
  background-color: rgba(55,55,55,0.75); 
  border: 1px solid rgba(255,255,255,0.1);
  border-radius:12px;
  box-shadow: 12px 12px 50px 2px rgba(0,0,0,0.5);; 
  width:700px;

padding: 50px 50px 50px 50px;
h1{
  color: #003200;
  font-weight:900;
  font-size:20px;
  margin:0;
  text-shadow: 2px 2px 4px #333;
}

@media (max-width:800px){
  left:16px;
  width:330px;
  padding:10px;
  margin:0;
  top:100px;

}
`;



const Fields = styled.div`
label{
  padding: 30px 0px 0px 0px;
}
input,textarea{
  width:500px;
  
  padding:10px 10px 10px 10px;

  border-color:white;
  text-shadow: 2px 2px 4px #333;
  background-color: rgba(255,255,255,0.05); 
  border: 1px solid rgba(255,255,255,0.1);
  border-radius:4px;
  
}
textarea{
  width:500px;
  max-width:590px;
  height: 160px;
  font-size:16px;
}

@media (max-width:800px){
  left:16px;
  width:330px;
  padding:0;
  margin:0;
  top:100px;

  input,textarea{
    left:0;
    padding:0;
    margin:0;
    width:300px;
max-width:300px;
min-width:300px;
    font-size:16px;
  }
}
`;


const Title = styled.div`
font-size: 32px;
font-weight: 900;
line-height: 1;
color:yellow;
text-align: left;
padding: 20px 0px 20px 0px;
text-shadow: 2px 2px 4px #333;
`;

const Submit = styled.div`
input,textarea{
 
  background-color: rgba(255,255,255,0.02);  /* translucent white background */
  border: 1px solid rgba(255,255,255,0.1);

  
  font-size:20px;
  font-weight:800;
  padding:2px 15px 2px 15px;
  border-radius:4px;
  cursor:pointer;


  padding: 8px 16px;
  text-transform: uppercase;
  letter-spacing: 1.5px;

  transition: all 0.2s ease 0s;

  &:hover {

    color:rgb(0,0,0,.5);
    background-color: rgb(255,255,255,.75);
  }
}

`;

export default Contact;
