import React, { useRef, useState, useEffect } from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styled from "styled-components";
import { Helmet } from "react-helmet";

const Daydreamer2 = () => {


  const [scrollPos, setScrollPos] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScroll = () => {
    setScrollPos(window.scrollY);
  };



  return (
    <>
      <Helmet>
     
        <title>Daydreamer2</title>
        <meta name="description" content="Daydreamer 2: a 3D action-packed sequel to the classic 2D side-scrolling game. Developed by a solo indie dev, it's a labor of love. Features fast-paced hack and slash combat, bullet time mechanics, and stylized physics for diverse player movement. Inspiration from Devil May Cry and PlatinumGames. A must-play for fans of the genre!" />
        <link rel="canonical" href ="/daydreamer2" />
     
    </Helmet>

    <Container>
    <Background scrollPos={scrollPos}>
        <video src="/videos/Background5.mp4" autoPlay loop muted />
        </Background>


    </Container>

      <BodyContainer>
        <Daydreamer2Logo>
        <img src="images/dd2logo.png"></img>
        </Daydreamer2Logo>
        <HeroMovie>
        <iframe src="https://player.vimeo.com/video/649365994?controls=1&autoplay=1&playsinline=1&color&autopause=0&loop=1&muted=1&title=1&portrait=1&byline=1#t="
        frameBorder="0"></iframe>
        </HeroMovie>
    <HeroText>
      <h1>The Next Chapter</h1>
      <p>
      As a solo indie game developer, bringing the beloved sequel of Daydreamer 2 to life is a labor of love. This game takes the classic 2D side-scrolling genre and elevates it with a thrilling 3rd person action perspective, drawing inspiration from acclaimed games like Devil May Cry and PlatinumGames. I have always envisioned Daydreamer 2 as a 3D game, waiting for the right time for the technology to advance and for my expertise to grow to take on this ambitious challenge.
<br></br><br></br>
The gameplay is fast-paced and action-packed, featuring rapid hack and slash combat and bullet time mechanics. The physics are stylized to offer a diverse range of player movement, including ground and air combat.
</p>
      <div className='two-column'>
      
    
      <p>
      <h1>Design & Inspiration</h1>
      I also drew inspiration from old-school NES classics like Guardian Legend by Compile, keeping a minimalist approach to the game design, with a stripped-down interface and a focus on pure action, shooting, leveling up and fighting bosses, rather than unnecessary features like crafting and fetch quests. This game is all about getting right into the action and immersing yourself in a thrilling gameplay experience, similar to how Guardian Legend featured two different game modes, a top-down adventure and a side-scrolling space shooter, Daydreamer 2 offers a top-down tank shooting and 3rd person action. 
      <br></br><br></br>
In addition to the fast-paced gameplay, I also wanted to retain the charm of older games by using text prompts to tell the story, giving only little snippets of exposition and leaving the player's imagination to do the rest. I wanted to create an old-school feeling, but with some state of the art graphics pipeline and cool modern conventions inspired from Platinum and Devil May Cry, such as the double jump, air combat, combos, stylized shooting, etc.
 <br></br><br></br>
 In terms of gameplay, Daydreamer 2 is a shooter, but it doesn't focus so much on aiming and marksmanship, but rather uses it as a layer on top of action platforming, incentivizing the player to tactically observe their surroundings rather than point and shoot. This encourages players to be strategic and use their environment to their advantage, instead of just relying on their aim.

Players will have to think fast and act fast, as they will be facing hordes of enemies and challenging bosses, but with the variety of abilities, weapons, and movement options at their disposal, they will be able to come up with unique and creative ways to defeat them.
  </p> 
      
      <div className = 'vert-gallery'>
      <iframe  src="https://player.vimeo.com/video/791028543?controls=0&autoplay=1&playsinline=1&color&autopause=0&loop=1&muted=1&title=1&portrait=1&byline=1#t=">
      </iframe>
      <img src="images/GaurdianShooter.png"></img>
      <img src="images/Daydreamer2/Tank.jpg"></img>
      </div>
      </div>
      <h1>CHALLENGES</h1>
      <p>Building a full game as an indie developer is a humbling and challenging task, especially with each new generation of technology raising the bar for player expectations. From the high-quality graphics to the immersive gameplay, sound, and player interaction, the demands of players are always increasing. In order to meet these demands, it is crucial to make the most of limited resources, and to be creative and innovative in the development process.
<br></br><br></br>
At the beginning of my journey, I was against the use of any automated tools, preferring to build everything from scratch, including characters, worlds, and special effects. However, as I progressed, I realized that building a vast, non-linear and interconnected world, like a "Metroidvania" game, in a reasonable time frame was not feasible for a single person. This led me to experiment with level generators, but I found that they often felt formulaic and uninspired.
<br></br><br></br>
</p>
</HeroText>
      <HeroMovie>
      <iframe src="https://player.vimeo.com/video/791027726?controls=1&autoplay=1&playsinline=1&color&autopause=0&loop=1&muted=1&title=1&portrait=1&byline=1#t=">
      </iframe>
      </HeroMovie>
      <HeroText>
        <p>
      To overcome this challenge, I decided to build my own level generator from the ground up. Starting with simple randomized nodes, I generated interesting paths with a clear start and end point. I then added bridges to connect nearby nodes, breaking the linearity of the level. Using this as a base, I built a wireframe skeleton that I could easily modify and reshape as needed. From there, I added walls and curved wedges to block out corridors, and sampled each hall to build randomized platforming with various themes. I then generated terrain and auto-painted it to create a natural feeling trenches. After that, I detailed the level with crates, wall decor, and environmental-specific items. Finally, I sampled each hall to build enemy proxies that worked with a global pooling system in place, and auto-baked everything from navmesh, occlusion culling, reflection probes, light probes, and global illumination.

</p>
<h1>Character Progression</h1>
<p>The character is undergoing ongoing evolution, with hand-animated movements using a custom rig. This rig includes advanced blending, stretching, overlapping, and facial control functions to achieve a desired look and play style. The rig is constantly modified to suit the environment and player input. The animation is not only focused on visual appeal, but also on instant playback response to player input, which can sometimes take precedence over natural-looking movements.</p>


        
<VidoeGridContainer>
<iframe src="https://player.vimeo.com/video/791027816?controls=0&autoplay=1&playsinline=1&color&autopause=0&loop=1&muted=1&title=1&portrait=1&byline=1#t=">
      </iframe>
      <iframe src="https://player.vimeo.com/video/791027851?controls=0&autoplay=1&playsinline=1&color&autopause=0&loop=1&muted=1&title=1&portrait=1&byline=1#t=">
      </iframe>
      <iframe src="https://player.vimeo.com/video/791027792?controls=0&autoplay=1&playsinline=1&color&autopause=0&loop=1&muted=1&title=1&portrait=1&byline=1#t=">
      </iframe>
      <iframe src="https://player.vimeo.com/video/791027872?controls=0&autoplay=1&playsinline=1&color&autopause=0&loop=1&muted=1&title=1&portrait=1&byline=1#t=">
      </iframe>
      <iframe src="https://player.vimeo.com/video/791054176?controls=0&autoplay=1&playsinline=1&color&autopause=0&loop=1&muted=1&title=1&portrait=1&byline=1#t=">
      </iframe>
      <iframe src="https://player.vimeo.com/video/791055035?controls=0&autoplay=1&playsinline=1&color&autopause=0&loop=1&muted=1&title=1&portrait=1&byline=1#t=">
      </iframe>
</VidoeGridContainer>

<h1>The Bosses</h1>
<p>Bosses are a crucial element in any game, and designing their AI is a true art form. In Daydreamer, players will encounter both familiar characters from the original game as well as new additions. The core concept behind the design of these bosses is to define the tactical theme of each encounter, whether it be a brute force approach, a strict pattern tactic, or a more heuristic strategy. By gradually layering on features that balance the boss's threats and weaknesses.</p>


        
<VidoeGridContainer>
<iframe src="https://player.vimeo.com/video/791060140?controls=0&autoplay=1&playsinline=1&color&autopause=0&loop=1&muted=1&title=1&portrait=1&byline=1#t=">
      </iframe>
      <iframe src="https://player.vimeo.com/video/791060151?controls=0&autoplay=1&playsinline=1&color&autopause=0&loop=1&muted=1&title=1&portrait=1&byline=1#t=">
      </iframe>
      <iframe src="https://player.vimeo.com/video/791060173?controls=0&autoplay=1&playsinline=1&color&autopause=0&loop=1&muted=1&title=1&portrait=1&byline=1#t=">
      </iframe>
      
</VidoeGridContainer>

</HeroText>

      </BodyContainer>
     </>
  );
};
const Container = styled.div`

 @media (max-width:800px)
 {
  width:100vw;
  width:400px;
  overflow:hidden;
 }
`;
const Background = styled.div`

  background-color: rgb(5,5,5);
  position: fixed;
  top:0;
  opacity: 1;
  z-index: -3;

  width: 100vw;
  height: 100vh;
  
  filter: ${({ scrollPos }) => `blur(${Math.min(scrollPos / 5, 30)}px) brightness(${ Math.max(100-scrollPos/5,40)}%)`};
  video { 
    width: 125%;
    height: auto;
  }

  @media (max-width:800px)
  {
   width:100%;
   overflow:hidden;
  }
 
`;

const Daydreamer2Logo = styled.div`
  opacity:0;
  animation: move-left .5s .25s ease-in-out;
  animation-fill-mode: forwards ;
 
  @media (max-width:800px)
  {
    width:240px;
    img
    {
      width:240px;
    } 
  }

  @keyframes move-left {
  0% {
    opacity: 0;
    transform: scale(150%) translateX(-15%) translateY(20%);
  }
  100% {
    opacity: 1;
    transform: scale(150%) translateX(15%) translateY(20%);
  }

 
}
`;

const HeroMovie = styled.div`
width: 1000px;
height:562px;
margin: 0 auto;
iframe{
  width: 1000px;
  height:562px;
  border-radius: 12px; 
  box-shadow: 4px 4px 2px 2px rgba(0,0,0,0.25);;  
  border: solid 2px rgba(255,255,255,0.3);
}

@media (max-width: 800px) {
  width: 330px;
  height: 280px;  
  margin:0;
  padding:0;
  flex-wrap:normal;
  white-space: normal;
 
  

  iframe{
    padding:0;
    margin:0;
    width: 500px;
    height:281px;
  }
}
`;

const HeroText = styled.div`
width:1000px;
margin: 0 auto;
p{
  font-size:17px;
  font-weight:100;
}
h1{
  font-size:32px;
  font-weight:600;
  color:yellow;
  white-space: normal;
  overflow:visible;
}
.two-column
{
  display: grid;
  gap:24px;
  grid-template-columns: repeat(2, 1fr); /* 2 columns */
  /* other styles */
  @media (max-width: 800px) {
    grid-template-columns: repeat(1, 1fr); /* 1 column */
  }
}
iframe 
  {
  width: 460px;
  height: 260px;
  border-radius: 12px; 
  box-shadow: 4px 4px 2px 2px rgba(0,0,0,0.25);;  
  border: solid 2px rgba(255,255,255,0.3);
} 
img
  {
  width: 460px;
  height: auto;
  border-radius: 12px; 
  box-shadow: 4px 4px 2px 2px rgba(0,0,0,0.25);;  
  border: solid 2px rgba(255,255,255,0.3);
} 


@media (max-width: 800px) {
  width: 330px;
  margin:0px;
}
`;

const BodyContainer =styled.div`
padding-top:100px;
position:absolute;
left:70px;
min-height:4800px;

@media (max-width:800px)
{
 width:400px;
 overflow:hidden;
  left:24px;
  p
  {
    max-width:340px;
  }
  img{
    max-width:330px;
  }
  iframe
  {
    max-width:340px;
    max-height:200px;
  }
}

`;



const VidoeGridContainer = styled.div` 
    padding:32px 0;
    width: 1000px;
    height: auto;
    display: grid;
    grid-template-columns: repeat(3, 360px);
    grid-gap:30px 0px;
    justify-content: center; /* center items horizontally */
    align-items: center; /* center items vertically */
    margin: 0 auto;

    iframe {
      margin:0;
      padding:10;
      width: 320px;
      height: 190px;
      border-radius: 12px; 
      box-shadow: 4px 4px 2px 2px rgba(0,0,0,0.25);;  
      border: solid 2px rgba(255,255,255,0.3);
    }

    @media (max-width: 800px) {
      grid-template-columns: repeat(1, 1fr); /* 1 column */
    }

`;





export default Daydreamer2;