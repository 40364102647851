import React, {  useState, useEffect } from 'react';
import styled  from 'styled-components';

const GetSlogsData = () => {

  const [initialData, setInitialData] = useState([]);
  const [apiData, setApiData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState(1);


  const [pageSize, setPageSize] = useState(25);
  const [page, setPage] = useState(0);
  const totalItems = tableData.length;
  const maxPage = Math.ceil(totalItems / pageSize) - 1;

const handlePageChange = newPage => {
  setPage(Math.max(0, Math.min(newPage, maxPage)));
};

        async function fetchData() {
        const response = await fetch('https://nft.rolandstudios.com/GetAllCollectorStats.php');
        const data = await response.text();
        setApiData(data);
      }
     
      useEffect(() => {
        fetchData();
      }, []);

     useEffect(() => {
          if (apiData) {
            let rows = apiData.toString().split('|');
            setInitialData(
              rows.map((row, index) => {
                let columns = row.toString().split(';');
                return {
                  id: index,
                  data: columns,
                };
              })
            );
            setTableData(rows.map((row, index) => {
              let columns = row.toString().split(';');
              return {
                id: index,
                data: columns,
              };
            }));
          }    
        }, [apiData]);
        
        const sortData = (index) => {
          if (index === sortColumn) {
            setSortDirection(sortDirection * -1);
          } else {
            setSortColumn(index);
            setSortDirection(1);
          }
        
          if (index === '0') {
            setTableData(
              [...initialData].sort((a, b) => {
                let aValue = a.data[index];
                let bValue = b.data[index];
        
                // Pad string with zeros if length is less than 15
                if (aValue.length < 19) {
                  aValue = aValue.padStart(19, '0');
                }
                if (bValue.length < 19) {
                  bValue = bValue.padStart(19, '0');
                }
        
                return aValue > bValue ? sortDirection : sortDirection * -1;
              })
            );
          } else {
            setTableData(
              [...initialData].sort((a, b) => (a.data[index] > b.data[index] ? sortDirection : sortDirection * -1))
            );
          }
        };
    

      return (
        <>
        {/*
        <BackgroundContainer>  
            
      </BackgroundContainer> */}
        <Chart>
        <h1>SLOG CHARTS</h1>
        <ChartPage>  
  <button onClick={() => setPage(Math.max(0, page - 1))}>&lt;&lt;  </button>  
  <p>[Page: {page + 1} of {maxPage + 1} ]</p>      
  <button onClick={() => setPage(Math.min(maxPage, page + 1))}>&gt;&gt;</button>
</ChartPage> 
            <table>
                <thead>
                    <tr>
                        <th onClick={() => sortData('0')}>Happy Collector Nft</th>
                        {/*<th onClick={() => sortData('1')}>Token Address</th>*/}
                        <th onClick={() => sortData('2')}>Owner</th>
                        <th onClick={() => sortData('3')}>NickName</th>
                        <th onClick={() => sortData('4')}>Chud</th>
                         {/*<th onClick={() => sortData('5')}>Time</th>
                        <th onClick={() => sortData('6')}>Inventory</th>
                        <th onClick={() => sortData('7')}>Health</th>
                        <th onClick={() => sortData('8')}>Magic</th>*/}
                        <th onClick={() => sortData('9')}>Xp</th>
                        <th onClick={() => sortData('10')}>Lvl</th>
                       {/* <th onClick={() => sortData('11')}>Community</th>
                        <th onClick={() => sortData('12')}>Elo</th> */}
                    </tr>
                </thead>
                <tbody>
                {tableData.slice(page * pageSize, (page + 1) * pageSize).map(
    (row, index) => (

      <tr key={index} className="fade-in" style={{'--index': index}}>
        {row.data.map((cell, cellIndex) => 
        {
          if (cellIndex === 0 && (!cell || cell === ""))
          {
            return<tr></tr> ;
          }
          else
          {
         
          if (cellIndex === 1) {
            return (
              <React.Fragment key={cellIndex}>
                <td>{"["+cell.substring(0, 4) + "...." + cell.substring(cell.length - 4)+"]"}</td>
              </React.Fragment>
            );
          } else if ([1,2, 5, 6,  7,8, 11,12].indexOf(cellIndex) === -1) {
            return (
              <React.Fragment key={cellIndex}>
                {cellIndex === 0 ? <td><img src={"https://nft.rolandstudios.com/happycollectors/" + cell.substring(16) + ".jpg"} alt="Image"></img> {cell}</td> 
                : <td>{cell}</td>}
              </React.Fragment>
            );
          }
        }
        })}
      </tr>
    )
  )}
</tbody>
            </table>

       
        </Chart>
        </>
    );
  }

  const ChartPage=styled.div`
  display:flex;
  position:relative;
  height:80px;
  gap:12px;
  align-items:center;
    left:100%;

  button{
   padding:8px;
    color:yellow;
    background-color:gray;
    border-radius:8px;
    box-shadow 4px 4px 4px rgba(20,20,20,.5);
  }
`;
  
  const Chart=styled.div`

  position: relative;
  top:0px;
  left:25px;
  border-radius:8px;
  border:none;
  max-width:600px;
  
  height:1800px;
  text-shadow: 1px 1px 2px rgb(0 0 0 / 0.5);
 
  h1{
    color:yellow;
    font-size:50px;
    font-weight:900;
    margin-bottom:-50px;
    text-shadow: 4px 4px 8px rgb(0 0 0 / 0.5);
  }

  th{
    color:yellow;
    cursor: pointer;
    padding: 16px 16px;
    font-size:20px;
    text-align:left;
    text-shadow: 1px 1px 2px rgb(0 0 0 / 0.5);
    background-color: rgb(125,125,125);
    box-shadow 4px 4px 4px rgba(20,20,20,.5);
}

th:first-child {
    border-top-left-radius: 16px;
    min-width:200px;
  }

  th:last-child {
    border-top-right-radius: 16px;
  }
  


  table
  {
    width:100%;
    text-shadow: 1px 1px 2px rgb(0 0 0 / 0.5);
    border:none;
    //box-shadow 2px 2px 4px rgba(20,20,20,.75);
    
  margin: 0;
  padding: 0;
  }

  tr{
    padding: 0 16px;
    text-shadow: 1px 1px 2px rgb(0 0 0 / 0.5);
    border:none;
  }

  table tr:nth-child(odd) 
  {
    background-color: rgb(77,77,77);
    box-shadow 2px 2px 12px rgba(20,20,20,.75);

    }

    table tr:nth-child(even) 
    {
       background-color:  rgb(55,55,55);
       box-shadow 2px 2px 12px rgba(20,20,20,.75);
    }

    td{
        padding: 8px;
    }

    td:nth-child(1) {
      font-size: 14px;
      font-weight:700;
      color: white;
      padding: 8px;
      min-width:300px;
      display: flex;
      align-items: center;
      vertical-align: middle;
      padding-left: 24px ;
    }
    
    img{
      border-radius:8px;
      margin-right:16px;
      height: 64px;
      width: 64px;
      vertical-align: middle;
      box-shadow 2px 2px 2px rgba(20,20,20,.5);
    }
    td:nth-child(2) {
        font-size: 12px;
        color:rgba(200,200,200,.75);
        padding: 12px;
        text-align:center;
        min-width:120px
      }

      td:nth-child(3) {
        font-size: 12px;
      
        padding: 12px;
        text-align:center;
        min-width:100px
      }

      td:nth-child(4) {
        color:magenta;
        text-shadow: 1px 1px 0px lime;
        font-size: 12px;
        padding: 12px;
        text-align:center;
        min-width:120px
      }

      td:nth-child(5) {
        font-size: 12px;
        padding: 12px;
        text-align:center;
        min-width:50px
      }

      td:nth-child(6) {
        font-size: 12px;
        padding: 12px;
        text-align:center;
        min-width:50px
      }

      .fade-in {
        opacity: 0;
        animation: fade-in 0.75s ease-in-out calc(0.1s * var(--index)) forwards;
      }

      @keyframes fade-in {
        from {
          transform: translateX(-100%);
          opacity: 0;
        }
        to {
          transform: translateX(0%);
          opacity: 1;
        }
      }
    `;


    const BackgroundContainer = styled.div`
    position:absolute;
  height:5200px;;
  width:100%;
  left:0;
  top:0;
  z-index:-2;
  min-height:3200px;
  animation: move-diagonally 8s linear infinite;
  
 
  
  background: url(/images/checkertile.png) repeat;
  @keyframes move-diagonally {
    from {
      background-position: 0 0;
    }
    to {
      background-position: -600px -600px;
    }
  }
  
`;
export default GetSlogsData;