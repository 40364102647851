import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  recommend: null,
  newDisney: null,
  original: null,
  trending: null,
  all: null,
  animation: null,
  motionDesign:null,
  webInteractive:null,
  commercial:null,
  medical:null,
  corporate:null,
  education:null,
  gaming:null,
  reels:null
};

const movieSlice = createSlice({
  name: "movie",
  initialState,
  reducers: {
    setMovies: (state, action) => {
      state.recommend = action.payload.recommend;
      state.newDisney = action.payload.newDisney;
      state.original = action.payload.original;
      state.trending = action.payload.trending;

      state.all = action.payload.all;
      state.animation = action.payload.animation;
      state.motionDesign = action.payload.motionDesign;
      state.webInteractive = action.payload.webInteractive;
      state.commercial = action.payload.commercial;
      state.medical = action.payload.medical;
      state.corporate = action.payload.corporate;
      state.education = action.payload.education;
      state.gaming = action.payload.gaming;
      state.reels = action.payload.reels;
    },
  },
});

export const { setMovies } = movieSlice.actions;

export const selectRecommend = (state) => state.movie.recommend;
export const selectNewDisney = (state) => state.movie.newDisney;
export const selectOriginal = (state) => state.movie.original;
export const selectTrending = (state) => state.movie.trending;

export const selectAll = (state) => state.movie.all;
export const selectAnimation = (state) => state.movie.animation;
export const selectMotionDesign = (state) => state.movie.motionDesign;
export const selectWebInteractive = (state) => state.movie.webInteractive;
export const selectCommercial = (state) => state.movie.commercial;
export const selectMedical = (state) => state.movie.medical;
export const selectCorporate = (state) => state.movie.corporate;
export const selectEducation = (state) => state.movie.education;
export const selectGaming = (state) => state.movie.gaming;
export const selectReels = (state) => state.movie.reels;

export default movieSlice.reducer;
